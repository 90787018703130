import React, {useEffect, useLayoutEffect, useReducer, useState} from "react"
import Player from '@vimeo/player';


function VideoQuestion(props) {

    let player;
    let video = props.videoId;
    const [id, setId] = useState(props.page);
    const [count, forceUpdate] = useReducer(x => x + 1, 0);
    const [autoplay, setAutoplay] = useState(props.autoplay);
    let regEx = '(https?://)?(www.)?(player.)?vimeo.com/([a-z]*/)*([0-9]{6,11})[?]?.*';
    if (props.videoId.includes('vimeo') || props.videoId.includes('http'))
        video = Number(video.match(regEx)[5]);
    //(props.autoplay && props.activeTab) for autoplay
    useEffect(() => {

        player = new Player(`video-container-${id}`, {
            id: video,
            controls: true,
            title: false,
            responsive: true,
            portrait: false,
            autoplay: false,
            loop: false
        });

        player.on('play', function () {
            console.log('played the video!');
        });

        player.pause();


    }, [props, id, autoplay, props.activeTab, props.videoId])


    useLayoutEffect(() => {
        forceUpdate()
        setId(props.page)
    }, [props.videoId, id, props.qPage, props.currentPage])


    return (
        <div className={'flex flex-col pb-3 justify-center items-center '}>

            <div id={`video-container-${id}`} className={'w-full md:max-w-3xl'}></div>

        </div>
    )
}

export default VideoQuestion