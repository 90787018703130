import React from "react"
import Player from '@vimeo/player';
import {getIntroductionVideoText} from "../util/Translations";


class VideoThumbnail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {videoI: this.props.videoId, showMainVideo: this.props.showMainVide};
    }


    getVi() {
        let props = this.props;
        let video = props.videoId;
        let regEx = '(https?://)?(www.)?(player.)?vimeo.com/([a-z]*/)*([0-9]{6,11})[?]?.*';
        if (props.videoId.includes('vimeo') || props.videoId.includes('http')) {
            video = Number(video.match(regEx)[5]);
        }
        return video;
    }

    addVideo = () => {
        let props = this.props;
        // const player;
        let video = props.videoId;
        let regEx = '(https?://)?(www.)?(player.)?vimeo.com/([a-z]*/)*([0-9]{6,11})[?]?.*';
        if (props.videoId.includes('vimeo') || props.videoId.includes('http')) {
            video = Number(video.match(regEx)[5]);
            this.setState({videoId: video})
        }

        const player = new Player(`video-container-thumb-${this.getVi()}`, {
            id: this.getVi(),
            controls: true,
            title: false,
            responsive: true,
            portrait: false,
            autoplay: false,
            loop: false
        });

        let id = this.getVi()
        let removeChild = this.removeChild;
        player.on("play", function () {
            console.log('played the video!');
            console.log("div", id)
            removeChild()
            if (!props.showMainVide) {
                props.setShowMainVideo(true);
            }
            // player.requestFullscreen();
        })


    }


    addChild() {
        const div = document.createElement("div")
        div.id = `video-container-thumb-${this.getVi()}`
        div.classList.add("w-full")
        document.getElementById("video-container-thumb-main").appendChild(div)
    }


    componentDidMount() {
        if (!this.props.showMainVide) {
            this.setState({videoId: this.getVi})
            this.addChild()
            this.addVideo();
        }
    }

    removeChild() {
        document.getElementById("video-container-thumb-main").firstElementChild.remove();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.videoId !== this.props.videoId && !this.props.showMainVide) {
            this.setState({videoI: this.props.videoId})
            this.setState({showMainVideo: this.props.showMainVide})
            this.removeChild()
            this.addChild()
            this.addVideo();
        }
    }

    render() {
        if (!this.props.showMainVide)
            return (
                <div className={"w-full"}>
                    <div id={"video-container-thumb-main"} className={'flex flex-col justify-center items-center'}>

                    </div>
                    <div className={"font-bold text-center text-xl py-2"}>
                        {getIntroductionVideoText(this.props.lang)}
                    </div>
                </div>
            )
        return null;
    }
}

export default VideoThumbnail
