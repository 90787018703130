import React from "react"
import Player from '@vimeo/player';

let player;
class VideoContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {videoI: this.props.videoId};
    }


    getVi() {
        let props = this.props;
        let video = props.videoId;
        let regEx = '(https?://)?(www.)?(player.)?vimeo.com/([a-z]*/)*([0-9]{6,11})[?]?.*';
        if (props.videoId.includes('vimeo') || props.videoId.includes('http')) {
            video = Number(video.match(regEx)[5]);
        }
        return video;
    }

    addVideo = () => {
        let props = this.props;
        // const player;
        let video = props.videoId;
        let regEx = '(https?://)?(www.)?(player.)?vimeo.com/([a-z]*/)*([0-9]{6,11})[?]?.*';
        if (props.videoId.includes('vimeo') || props.videoId.includes('http')) {
            video = Number(video.match(regEx)[5]);
            this.setState({videoId: video})
        }

        player = new Player(`video-container-${this.getVi()}`, {
            id: this.getVi(),
            controls: true,
            title: false,
            responsive: true,
            portrait: false,
            autoplay: false,
            loop: false
        });

        player.on("play", function () {
            console.log('played the video!');
        })

        player.on("pause", function () {
            props.setShowMainVideo(false)
        })

    }


    addChild() {
        const div = document.createElement("div")
        div.id = `video-container-${this.getVi()}`
        div.classList.add("w-full")
        document.getElementById("video-container-main").appendChild(div)
    }


    componentDidMount() {
        this.setState({videoId: this.getVi})
        this.addChild()
        this.addVideo();
        document.getElementById('root').scrollIntoView({behavior:'smooth'});
    }

    removeChild() {
        document.getElementById("video-container-main").firstElementChild.remove();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.videoId !== this.props.videoId) {
            this.setState({videoI: this.props.videoId})
            this.removeChild()
            this.addChild()
            this.addVideo();
        }
        if (this.props.showMainVide) {
            document.getElementById("video-container-main").scrollIntoView({behavior:"smooth"})
            player.play();
        }
    }

    render() {
        return (
            <div id={"video-container-main"} className={`${!this.props.showMainVide?"hidden":''} flex flex-col justify-center items-center `}>

            </div>
        )
    }
}

export default VideoContainer
