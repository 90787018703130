import FooterLogo from '../../assets/footer-logo.png'
import {getFooterText} from "../util/Translations";
const Footer = (props) => {
    return (
        <div className={"flex flex-col mt-2 py-12 px-8 sm:px-20 "}>
            <div className="flex flex-col md:flex-row grid grid-cols-1 md:grid-cols-12">
                <div className="footer-logo md:col-span-2">
                    <a className={'text-xs text-center flex flex-col justify-center text-blue-400 hover:underline items-center'} href="https://www.enforme.no/" target="_blank">
                        <img src={FooterLogo} className={'w-52 sm:w-full'}/>
                        www.enforme.no</a>
                </div>
                <div className={"sm:pl-8 text-xs text-left leading-5 md:col-span-10"}>
                    {getFooterText(props.language)}
                </div>
            </div>

        </div>
    )
}
export default Footer;