import Slider from '../../assets/temp/enforme-scale.png'
import Indicator from '../../assets/temp/GREEN.png'
import Star from '../../assets/temp/pi.png'
import Pi from '../../assets/temp/pi1.png'

const SurveyResponse = () => {
    return (
        <div>
            <div className="h-50 sm:h-80 bg-black shadow leading-6">
                <img className={"h-full w-full"} src="/logo.jpg"/>
            </div>
            <div className={'flex flex-col px-4 sm:px-20 py-12 my-1'}>

                {/*static text container with buttons*/}
                <div id={'static-text-container'} className={'flex flex-col relative'}>
                    <div
                        className={'sm:pt-14 grid grid-flow-col lg:grid-flow-row grid-rows-2 lg:grid-rows-1 grid-cols-1 lg:grid-cols-3'}>

                        <p className="text-sm col-span-1 md:col-span-2  md:mr-2 p-5 pl-0 leading-6 pr-6 pr-5 pr-4 pr-10 pr-8">
                            Her er dine resultater basert på svarene du har gitt. For hvert svar har du fått en
                            fargescore. Fargescorene kombineres med tekst som gir faktaopplysninger og tilbakemeldinger
                            på dine svar. På nest siste side finner du to figurer som oppsummerer alle resultatene dine.
                            Ønsker du noen å diskutere med eller søke råd hos, vil siste siden i rapporten gi deg
                            kontaktinformasjon til nøkkelpersoner ved skolen. Velger du å sende rapporten på e-post
                            anbefales det at du lagrer den etter at du har fått den i egen postkasse.
                        </p>
                        <div className={'md:max-w-sm'}>
                            <div
                                className={'dark-grey rounded-xl p-6 flex flex-col justify-center items-center'}>
                                <button className={'button-default button-gradient my-1 w-full text-lg'}>Print Rapport
                                </button>
                                <button className={'button-default button-gradient w-full text-lg'}>Send pa epost
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*tables*/}
                <div>
                    <div>
                        <table className="border-b font-light table table-auto min-w-full">
                            <tbody>
                            <tr className={'border-b odd:bg-white even:bg-gray-50'}>
                                <td className={'mr-4'}>Hjerte- og karsykdom</td>
                                <td>
                                    <h5>Nei</h5>
                                </td>
                            </tr>
                            <tr>
                                <td className={''} >Har foreldre hatt angina, hjerteinfarkt, slag, blodpropp eller bypassoperasjon før fylte 60år?</td>
                                <td >Nei </td>
                            </tr>
                            <tr>
                                <td>Høyt blodtrykk</td>
                                <td>
                                    <h5>Nei</h5>
                                </td>
                            </tr>
                            <tr>
                                <td >Hvis ja, medisin?</td>
                                <td >
                                    <h5>Nei</h5>
                                </td>
                            </tr>
                            <tr>
                                <td>Høyt kolesterol</td>
                                <td>
                                    <h5>Nei</h5>
                                </td>
                            </tr>
                            <tr>
                                <td >Hvis ja, medisin?</td>
                                <td >
                                    <h5>Nei</h5>
                                </td>
                            </tr>
                            <tr>
                                <td>Diabetes</td>
                                <td>
                                    <h5>Nei</h5>
                                </td>
                            </tr>
                            <tr>
                                <td >Hvis ja</td>
                                <td >
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                {/*questions container*/}
                <div id={'question-container'}>
                    <div className={'w-full my-1 green text-white font-bold py-2 pl-4 uppercase'}>KOSTHOLD</div>
                    {/*question 1*/}
                    <div className={'grey my-1'}>
                        <div className={'p-4'}>
                            <p>4. Hvor mange ganger i uken spiser du ferdigmat/fastfood til middag?</p>
                        </div>
                        <div className={'w-full p-4'}>
                            <img className={'w-full'} src={Slider} alt="slider"/>
                        </div>
                        <div className={'flex flex-col sm:flex-row md:items-center justify-center px-4 pb-8'}>
                            <div className={'w-9 md:w-24 sm:w-80  '}>
                                <img src={Indicator} alt="indicator"/>
                            </div>
                            <div className={'sm:pl-12 text-sm'}>
                                <p>Det er supert at du får i deg nok frukt og grønt. Tenk variasjon i farger og i
                                    fordelingen
                                    mellom
                                    frukt og grønnsaker. Det er også et klimamål at vi skal spise mer norske
                                    sesongbaserte
                                    grønnsaker på bekostning av kjøtt. Produksjon av kjøtt har et betydelig høyere
                                    klimautslipp
                                    enn
                                    grønnsaker. Frukt og grønt er godt for både kroppen din og planeten vår.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*question 2*/}
                    <div className={'grey my-1'}>
                        <div className={'p-4'}>
                            <p>2. Hvor mange ganger i uken spiser du ol til middag?</p>
                        </div>
                        <div className={'w-full p-4'}>
                            <img className={'w-full'} src={Slider} alt="slider"/>
                        </div>
                        <div
                            className={'flex flex-col sm:flex-row items-start sm:items-center justify-center px-4 pb-8'}>
                            <div className={'w-9 sm:w-1/6'}>
                                <img src={Indicator} alt=""/>
                            </div>
                            <div className={'text-sm sm:w-5/6'}>
                                <p className={'enable-links'}>Du spiser sjelden om morgenen. Studier tyder på at det å
                                    spise frokost har en gunstig effekt på konsentrasjon og hukommelse. En frukt, et
                                    knekkebrød, yoghurt&nbsp;eller et glass melk/juice, evt. en liten&nbsp;nøtteblanding
                                    kan være gode alternativ dersom du strever med å spise tidlig. Eventuelt kan du lage
                                    en frokostmatpakke og ta med og spise når sulten melder seg.&nbsp;<a
                                        href="https://www.matportalen.no/kosthold_og_helse/tema/kostrad/frokost">Les
                                        gjerne her om gode frokostvaner.&nbsp;</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                {/*    summary */}
                <div>
                    <div className={'w-full my-1 dark-grey font-bold py-2 pl-4'}>OPPSUMMERING LIVSMESTRING</div>
                    <div className={'grey p-8 grey'}>
                        <p>Du opplever sjelden å få nok søvn i løpet av uken. Noen ganger er også skjermbruk er en
                            viktig årsak til dette. For lite søvn kan gjøre deg ukonsentrert, hemme skoleprestasjonene
                            og påvirke humøret. Tenk gjennom hva du kan gjøre for å få nok søvn som er ca 8-9 timer hver
                            natt.

                            Du er ikke alltid helt fornøyd med hvordan du har det, men samtidig er du sjelden trist og
                            bekymret. Det er vanlig å oppleve at livet veksler mellom gode og mindre gode perioder. Tenk
                            gjerne gjennom hva som gjør deg glad og hva som gjør deg trist, og fokuser mer på det som
                            gjør deg glad og gir deg energi enn negative tanker.

                            Sosiale medier, gaming etc går i stor grad ut over jevnlig kontakt ansikt til ansikt med
                            vennene dine. Man kan ha glede av å kommunisere med andre på nett, men det gir også stor
                            glede å treffe venner for å henge, prate og gjøre ting sammen. Tenk gjennom om det er noe du
                            kan gjøre for å finne en god balanse mellom skjermbruk og det å treffe venner.

                            Det er smart at du ikke gjør flere ting samtidig mens du gjør lekser. Det viser seg at vi
                            presterer best og bruker kortere tid når vi gjør en ting av gangen.

                            Når man er i tenårene endrer livet seg, man gjør nye erfaringer, kroppen utvikler seg, man
                            opplever nye forventninger og belastninger. Derfor opplever mange at følelser og humøret
                            varierer i perioder. Å kunne håndtere ubehagelige følelser er nyttig. Du kan påvirke
                            følelsene gjennom hva du gjør - og tenk gjennom hvilke handlinger som gjør at du har det
                            bra.</p>
                    </div>
                </div>

                {/*    star graph */}
                <div id={'star-graphs-container'} className={''}>
                    <div className={'w-full mt-1 dark-grey font-bold py-2 pl-4 text-xl'}>OPPSUMMERING KOSTHOLD</div>
                    <div
                        className={'flex flex-col grey sm:flex-row justify-center items-center p-8 grid grid-cols-1 lg:grid-cols-2 gap-6'}>
                        <div className={'sm:px-5'}>
                            <img className={'w-full'} src={Star} alt="star"/>
                        </div>
                        <div className={'text-sm py-2 sm:px-5'}>
                            <p>
                                Du har scoret deg selv slik at din gjennomsnittsverdi av alle kostholds spørsmål er 4,8
                                (på en skala fra 1-6). Dette er meget bra. De uthevede områdene i figuren viser hva du
                                er best på og det området hvor du har størst utfordring. Gå gjerne tilbake til aktuelle
                                spørsmål og se om rådene som gis i tilbakemeldingene kan være interessante og nyttige
                                for deg.
                            </p>
                        </div>
                    </div>
                </div>

                {/*    pi graph */}
                <div id={'pi-graphs-container'} className={''}>
                    <div className={'w-full mt-1 dark-grey font-bold py-2 pl-4'}>OPPSUMMERING KOSTHOLD</div>
                    <div
                        className={'flex flex-col grey sm:flex-row justify-center items-center p-8 grid grid-cols-1 sm:grid-cols-2 gap-6'}>
                        <div className={'text-sm py-2 sm:px-5'}>
                            <p>
                                Du har scoret deg selv slik at din gjennomsnittsverdi av alle kostholds spørsmål er 4,8
                                (på en skala fra 1-6). Dette er meget bra. De uthevede områdene i figuren viser hva du
                                er best på og det området hvor du har størst utfordring. Gå gjerne tilbake til aktuelle
                                spørsmål og se om rådene som gis i tilbakemeldingene kan være interessante og nyttige
                                for deg.
                            </p>
                        </div>
                        <div className={'sm:px-5 flex flex-col justify-center items-center text-sm'}>
                            <p>Du har i sum en score på 2,3. Dette viser at du har en del forbedringsområder når det
                                gjelder egne valg og livsstilsvaner.</p>
                            <div className={'flex flex-col justify-center'}>
                                <img className={'w-full'} src={Pi} alt="star"/>

                                <div className={'h-full sm:h-40 flex flex-col sm:flex-row justify-center sm:justify-between'}>
                                    <span className={'flex flex-col text-center justify-center items-center content-center'}>
                                        <img className={'h-40 w-40 sm:h-full'} src={Pi} alt="star"/>
                                        <p>june 21</p>
                                    </span>
                                    <span className={'flex flex-col text-center justify-center items-center content-center'}>
                                        <img className={'h-40 sm:h-full'} src={Pi} alt="star"/>
                                        <p>feb 21</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*    final regards */}
                <div className={'text-sm grey p-8 pt-14 mt-2'}>
                    <p>
                        Du har nå lest igjennom rapporten som oppsummerer dine livsstilsvaner. En rapport som denne vil
                        ikke gjenspeile livstilen din 100% nøyaktig, men den gir likevel en god pekepinn på hvilke valg
                        du tar når det gjelder egen helse. Fortsett med dine gode vaner!

                        <br/>
                        <br/>

                        For å hjelpe deg til å ta gode valg, får du nå i oppgave til neste uke å lage deg en tiltaksplan
                        for å ivareta god helse. Tiltaksplanen som skal fylles ut blir lagt ut på kroppsøvingsteamet.
                        Det kan være god hjelp å utarbeide seg målsetninger for å endre livsstil og holde på gode vaner.
                        <br/>
                        <br/>


                        Det kan være nyttig å diskutere rapporten med familie, venner, kontaktlærer eller
                        skolehelsetjenesten.
                        <br/>
                        <br/>


                        Ønsker du en samtale omkring rapporten kan du ta kontakt med:
                        <br/>
                        <br/>

                        Elevtjenesten som du finner i 2. etasje i A-bygget.

                        <br/>
                        <br/>
                        <ul className={'px-5 list-disc'}>
                            <li className={'list-item'}>
                                Rådgiver: Katrine Nyhus Sande, på telefon 32 11 34 14
                            </li>
                            <li>
                                Helsesykepleier Anita Håndstad Holtan Mobil: 974 20 343
                                <br/>
                                E-post: anitahol@viken.no
                            </li>
                            <li>
                                Kontaktlærer
                            </li>
                            <li>
                                Kroppsøvingslærer
                            </li>

                        </ul>
                        <br/>
                        Ved sykdom er det viktig å ta kontakt med sin fastlege.
                    </p>
                </div>

            </div>
        </div>

    )
}

export default SurveyResponse;
