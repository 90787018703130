import {
    ADD_ANSWER_TO_RESPONSE,
    addAnswer,
    CATCH_ERROR, catchError, showErrorAlert, SHOW_ERROR_ALERT, GET_REQUEST_PENDING, getRequestPending,
    POST_RESPONSE,
    postResponse, STORE_IGNORE_ACTIVITY, STORE_KEY, STORE_PERSON_ID, STORE_RESPONSE_ID, storeIgnoreActivityAction,
    storeKeyAction, storePersonIdAction,
    storeResponseIdAction,
    SHOW_REQUEST_PASSWORD, showRequestPasswordPopup, PASSWORD_REQUESTED, passwordRequested
} from "./action";
import {fetchLicenseKey, submitSurveyResponse} from "../service";
import {error} from "autoprefixer/lib/utils";

const initState = {
    surveyResponse: {},
    posted: false,
    error: false,
    showErrorAlert: false,
    licenseKey: '',
    personId: '',
    responseId: '',
    pending: false,
    ignoreActivity: false,
    requestPasswordShow: false,
    passwordSent: false,
}

export const addAnswerToSurveyResponse = (id, answer) => {
    return (dispatch, getState) => {
        let responseAnswers = getState().surveyResponse;
        responseAnswers[id] = answer;
        dispatch(addAnswer(responseAnswers))
    }
}

export const removeAnswerFromSurveyResponse = (id, answer) => {
    return (dispatch, getState) => {
        let responseAnswers = getState().surveyResponse;
        delete responseAnswers[id];
        dispatch(addAnswer(responseAnswers))
    }
}

export const alterLoading = (state) => {
    return (dispatch) => {
        dispatch(getRequestPending(state))
    }
}

export const storeLicenseKey = (key) => {
    return (dispatch) => {
        dispatch(storeKeyAction(key))
    }
}
export const storeResponseId = (responseId) => {
    return (dispatch) => {
        dispatch(storeResponseIdAction(responseId))
    }
}
export const storePersonId = (personId) => {
    return (dispatch) => {
        dispatch(storePersonIdAction(personId))
    }
}
export const storeIgnoreActivity = (ignore) => {
    return (dispatch, getState) => {
        console.log(getState().surveyResponse)
        dispatch(storeIgnoreActivityAction(ignore))
    }
}

export const alterRequestPasswordComponentState = (show) => {
    return (dispatch) => {
        dispatch(showRequestPasswordPopup(show))
        if (!show)
            dispatch(passwordRequested(false))
    }
}

export const requestPassword = (surveyId, mobile) => {
    return (dispatch) => {
        dispatch(getRequestPending(true))
        fetchLicenseKey(surveyId, mobile).then(response => {
            dispatch(passwordRequested(true))
            dispatch(getRequestPending(false))
        }).catch(error => {
            dispatch(getRequestPending(false))
        })
    }
}

export const postSurvey = (surveyId, ignoreActivity,language) => {
    return (dispatch, getState) => {
        console.log(getState())
        dispatch(getRequestPending(true))
        submitSurveyResponse(surveyId, getState().licenseKey, getState().personId, getState().responseId, getState().ignoreActivity, getState().surveyResponse,language).then(response => {
            if (response.status === 204) {
                dispatch(postResponse(true))
            }
            if (response.status === 500) {
                dispatch(catchError(`${response.status} ${Date.now().toLocaleString()}`))
                dispatch(showErrorAlert(true));
            }
            dispatch(getRequestPending(false))
        }).catch(error => {
            console.log(error)
            dispatch(catchError(error))
            dispatch(showErrorAlert(true));
            dispatch(getRequestPending(false))
        })
    }
}

export const alterErrorAlert = (showState) => {
    return (dispatch) => {
        dispatch(showErrorAlert(showState))
    }
}

export default (state = initState, action) => {
    switch (action.type) {
        case ADD_ANSWER_TO_RESPONSE:
            return {...state, surveyResponse: action.payload}
        case POST_RESPONSE:
            return {...state, posted: action.payload}
        case CATCH_ERROR:
            return {...state, error: action.payload}
        case SHOW_ERROR_ALERT:
            return {...state, showErrorAlert: action.payload}
        case STORE_KEY:
            return {...state, licenseKey: action.payload}
        case STORE_RESPONSE_ID:
            return {...state, responseId: action.payload}
        case STORE_PERSON_ID:
            return {...state, personId: action.payload}
        case STORE_IGNORE_ACTIVITY:
            return {...state, ignoreActivity: action.payload}
        case GET_REQUEST_PENDING:
            return {...state, pending: action.payload};
        case SHOW_REQUEST_PASSWORD:
            return {...state, requestPasswordShow: action.payload};
        case PASSWORD_REQUESTED:
            return {...state, passwordSent: action.payload};
        default:
            return state;
    }
}