import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import SurveyBaseContainer from "./components/SurveyBaseContainer";
import Loading from "./components/shared/Loading";
import SurveyResponse from "./components/shared/SurveyResponse";

const App = () => {

    if (!window.location.pathname.includes('survey'))
        window.location.replace('/enforme'+window.location.pathname)

    return (
        <Router>
            <div className={"py-2 pt-14 px-4 sm:px-6 lg:px-12"}>
                <div className="max-w-6xl bg-white mx-auto  h-full">
                    <Loading/>
                    <Routes>
                        <Route path={'/survey/:surveyId'} element={<SurveyBaseContainer byPassInit={false} />}/>
                        <Route path={'/survey/:surveyId/response/:responseId/personId/:personId'} element={<SurveyBaseContainer byPassInit={true} />}/>
                        <Route path={'/response'} element={<SurveyResponse/>}/>
                    </Routes>
                </div>
            </div>
        </Router>
    )
}


export default App;
