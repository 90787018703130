import React, {useEffect, useState} from "react";
import {CheckIcon} from "@heroicons/react/solid";
import SubGroup from "./SubGroup";
import {
    getBirthText,
    getConsentDetailedText,
    getConsentHeadingText,
    getConsentText,
    getEducationSpecText,
    getEmailText,
    getFirstNameText,
    getGenderFemaleAdultText,
    getGenderFemaleText,
    getGenderMaleAdultText,
    getGenderMaleText,
    getGenderText,
    getLastNameText, getMobileOptionText, getMobileOptionWriteText,
    getMobileText,
    getNextButtonText, getSeeMoreText,
    getSelectText,
    getUserFormHeadingText
} from "../util/Translations";

const UserForm = (props) => {
    const [seeMore, setSeeMore] = useState(false);
    const [user, setUser] = useState({firstName: '', lastName: ''});
    const [isFormValid, setIsFormValid] = useState(false);

    const [isValidFirstName, setIsValidFirstName] = useState(false);
    const [isValidLastName, setIsValidLastName] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidGender, setIsValidGender] = useState(false);
    const [isValidMobile, setIsValidMobile] = useState(false);
    const [isValidBorn, setIsValidBorn] = useState(false);
    const [isValidSubGroup, setIsValidSubGroup] = useState(false);
    const [isTermsAgreed, setIsTermsAgreed] = useState(false);
    const [isValidEducationSpec, setIsValidEducationSpec] = useState(false);

    const [noMobile, setNoMobile] = useState(false);

    const [mobileNo, setMobileNo] = useState();

    function addPropertyToUser(property) {
        let u = user;
        u[property.id] = property.value;
        setUser(u);
    }

    function allowFormSubmission() {
        if (props.subGroups.length === 0)
            setIsValidSubGroup(true);
        if (!props.requireFirstAndLastName) {
            setIsValidFirstName(true);
            setIsValidLastName(true)
        }
        if (props.targetGroup !== "1")
            setIsValidEducationSpec(true)

        if (isValidFirstName && isValidLastName && isValidEmail && isValidGender && isValidMobile && isValidBorn && isValidSubGroup && isValidEducationSpec && isTermsAgreed)
            setIsFormValid(true);
        else {
            if (isFormValid)
                setIsFormValid(false);
        }
    }

    useEffect(() => {
        allowFormSubmission();
    }, [isValidFirstName, isValidLastName, isValidEmail, isValidGender, isValidMobile, isValidBorn, isValidSubGroup, isTermsAgreed, isValidEducationSpec])


    function validateEmail(val) {
        let regEx = /\S+@\S+\.\S+/;
        if (regEx.test(val)) {
            setIsValidEmail(true);
        } else {
            if (isValidEmail)
                setIsValidEmail(false);
        }
    }

    function validateGender(val) {

        if (val.trim() !== '')
            setIsValidGender(true)
        else {
            if (isValidGender)
                setIsValidGender(false)
        }

    }

    function validateFirstName(val) {

        if (val.trim() !== '')
            setIsValidFirstName(true)
        else {
            if (isValidFirstName)
                setIsValidFirstName(false)
        }

    }

    function validateLastName(val) {

        if (val.trim() !== '')
            setIsValidLastName(true)
        else {
            if (isValidLastName)
                setIsValidLastName(false)
        }

    }

    function validateMobile(val) {

        let regEx = /^\d{8,10}$/;
        if (val.trim() !== '' && regEx.test(val))
            setIsValidMobile(true)
        else {
            if (isValidMobile)
                setIsValidMobile(false)
        }

    }

    function validateBorn(val) {
        let regEx = /^\d{4}$/;
        if (val.trim() !== '' && regEx.test(val) && val > 1900)
            setIsValidBorn(true)
        else {
            if (isValidBorn)
                setIsValidBorn(false)
        }

    }

    function validateSubGroup(val) {

        if (val.trim() !== '')
            setIsValidSubGroup(true)
        else {
            if (isValidSubGroup)
                setIsValidSubGroup(false)
        }

    }

    function validateEducationSpec(val) {

        if (val.trim() !== '')
            setIsValidEducationSpec(true)
        else {
            if (isValidEducationSpec)
                setIsValidEducationSpec(false)
        }

    }

    function generateRandomMobileNumber() {

        let randomNo = Math.floor(20000000 + (Math.random() * 9000000));
        setMobileNo(randomNo);
        addPropertyToUser({id: "mobile", value: randomNo});
    }


    return (

        <div className="mt-10 sm:mt-0 ">
            <div className=" grey px-2 sm:p-20">
                <div className="mt-5 md:mt-0 ">
                    <div >
                        <div className="text-3xl font-bold mb-3">
                            <h1>{getUserFormHeadingText(props.language)}</h1>
                        </div>
                        <div className="overflow-hidden sm:rounded-md">
                            <div className={'md:grid md:grid-cols-3 md:gap-6'}>
                                <div className="md:col-span-2 px-4 py-5 sm:p-6">

                                    <div className="grid grid-cols-6 gap-6">

                                        {/*subGroup*/}
                                        {props.subGroups.length > 0 &&
                                        <SubGroup addPropertyToUser={addPropertyToUser}
                                                  language={props.language}
                                                  validateSubGroup={validateSubGroup}
                                                  subGroups={props.subGroups.filter(s=>s.status!=='INACTIVE')}
                                                  targetGroup={props.targetGroup}
                                                  isValidSubGroup={isValidSubGroup}/>
                                        }
                                        {/*born*/}
                                        <div className="col-span-6 flex grid grid-cols-7">
                                            <label htmlFor="born"
                                                   className="block col-span-7 sm:col-span-2 text-sm font-medium flex items-center">
                                                {getBirthText(props.language)}
                                            </label>
                                            <input
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    addPropertyToUser(event.target)
                                                    validateBorn(event.target.value)
                                                }}
                                                required={true}
                                                placeholder={'1970'}
                                                type="text"
                                                name="born"
                                                id="born"
                                                maxLength={4}
                                                autoComplete="born"
                                                className="mt-1 p-2 col-span-6 sm:col-span-3  block w-full
                                                shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            <div className={'text-lg text-right flex justify-end'}>
                                                {isValidBorn ?
                                                    <CheckIcon className={'h-6 w-6'} color={'green'}/> :
                                                    <span className={'text-red-600 font-bold text-3xl h-5'}> *</span>}
                                            </div>
                                        </div>
                                        {/*gender*/}
                                        <div className="col-span-6 flex grid grid-cols-7">
                                            <label htmlFor="gender"
                                                   className="block col-span-7 sm:col-span-2 text-sm font-medium flex items-center">
                                                {getGenderText(props.language)}
                                            </label>
                                            <select
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    addPropertyToUser(event.target)
                                                    validateGender(event.target.value)
                                                }}
                                                required={true}
                                                id="gender"
                                                name="gender"
                                                autoComplete="gender-name"
                                                className="mt-1 col-span-6 sm:col-span-3 block w-full py-2 px-3 pr-8 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none  sm:text-sm"
                                            >
                                                <option value={''}>{getSelectText(props.language)}</option>
                                                <option
                                                    value={'MALE'}>{props.adults ? getGenderMaleAdultText(props.language) : getGenderMaleText(props.language)}</option>
                                                <option
                                                    value={'FEMALE'}>{props.adults ? getGenderFemaleAdultText(props.language) : getGenderFemaleText(props.language)}</option>
                                            </select>
                                            <div className={'text-lg text-right flex justify-end'}>
                                                {isValidGender ?
                                                    <CheckIcon className={'h-6 w-6'} color={'green'}/> :
                                                    <span className={'text-red-600 font-bold text-3xl h-5'}> *</span>}
                                            </div>
                                        </div>
                                        {/*email*/}
                                        {/*<div className="col-span-6 flex grid grid-cols-7">*/}
                                        {/*    <label htmlFor="email"*/}
                                        {/*           className="block col-span-7 sm:col-span-2 text-sm font-medium flex items-center">*/}
                                        {/*        {getEmailText(props.language)}*/}
                                        {/*    </label>*/}
                                        {/*    <input*/}
                                        {/*        onChange={(event) => {*/}
                                        {/*            event.preventDefault();*/}
                                        {/*            addPropertyToUser(event.target)*/}
                                        {/*            validateEmail(event.target.value)*/}
                                        {/*        }}*/}
                                        {/*        placeholder={'email@something.com'}*/}
                                        {/*        type="text"*/}
                                        {/*        name="email"*/}
                                        {/*        id="email"*/}
                                        {/*        autoComplete="email"*/}
                                        {/*        className="mt-1 p-2 col-span-6 sm:col-span-3  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"*/}
                                        {/*    />*/}
                                        {/*    <div className={'text-lg text-right flex justify-end'}>*/}
                                        {/*        {isValidEmail ?*/}
                                        {/*            <CheckIcon className={'h-6 w-6'} color={'green'}/> :*/}
                                        {/*            <span className={'text-red-600 font-bold text-3xl h-5'}> *</span>}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*Mobile*/}
                                        <div className={"col-span-6 flex flex-col"}>
                                            <div className="col-span-6 flex grid grid-cols-7">
                                                <label htmlFor="mobile"
                                                       className="block col-span-7 sm:col-span-2 text-sm font-medium flex items-center">
                                                    {getMobileText(props.language)}
                                                </label>
                                                <input
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        setMobileNo(event.target.value);
                                                        addPropertyToUser(event.target)
                                                        validateMobile(event.target.value)
                                                    }}
                                                    required={true}
                                                    type="text"
                                                    name="mobile"
                                                    id="mobile"
                                                    disabled={noMobile}
                                                    value={mobileNo}
                                                    autoComplete="phone"
                                                    className="mt-1 p-2 col-span-6 sm:col-span-3  block w-full
                                                shadow-sm sm:text-sm border-gray-300 rounded-md "
                                                />

                                                <div className={'text-lg text-right flex justify-end'}>
                                                    {isValidMobile ?
                                                        <CheckIcon className={'h-6 w-6'} color={'green'}/> :
                                                        <span
                                                            className={'text-red-600 font-bold text-3xl h-5'}> *</span>}
                                                </div>
                                            </div>

                                            <div className={"col-span-6 flex grid grid-cols-7"}>
                                                <div className={"hidden sm:block col-span-2"}>

                                                </div>
                                                <div className={"col-span-7 md:col-span-5"}>
                                                    <label htmlFor="no-mobile"
                                                           className={"cursor-pointer flex items-center"}>
                                                        <input className={""}
                                                               onChange={(e) => {
                                                                   setNoMobile(e.target.checked);
                                                                   setIsValidMobile(e.target.checked);
                                                                   if (e.target.checked)
                                                                       generateRandomMobileNumber();
                                                               }}
                                                               type="checkbox" name={"no-mobile"}
                                                               id={"no-mobile"}/>
                                                        <span
                                                            className={"px-2"}>{getMobileOptionText(props.language)}</span>
                                                    </label>
                                                    <div className={`${noMobile ? '' : 'hidden'} text-red-600`}>
                                                        {getMobileOptionWriteText(props.language)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*firstName */}
                                        {props.requireFirstAndLastName &&
                                        <div className="col-span-6 flex grid grid-cols-7">
                                            <label htmlFor="firstName"
                                                   className="block col-span-7 sm:col-span-2 text-sm font-medium">
                                                {getFirstNameText(props.language)}
                                            </label>
                                            <input
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    addPropertyToUser(event.target);
                                                    validateFirstName(event.target.value);
                                                }}
                                                required={true}
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                autoComplete="given-name"
                                                className="mt-1 p-2 col-span-6 sm:col-span-3  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            <div className={'text-lg text-right flex justify-end'}>
                                                {isValidFirstName ?
                                                    <CheckIcon className={'h-6 w-6'} color={'green'}/> :
                                                    <span className={'text-red-600 font-bold text-3xl h-5'}> *</span>}
                                            </div>
                                        </div>
                                        }
                                        {/*last name*/}
                                        {props.requireFirstAndLastName &&
                                        <div className="col-span-6 flex grid grid-cols-7">
                                            <label htmlFor="lastName"
                                                   className="block col-span-7 sm:col-span-2 text-sm font-medium">
                                                {getLastNameText(props.language)}
                                            </label>
                                            <input
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    addPropertyToUser(event.target);
                                                    validateLastName(event.target.value);
                                                }}
                                                required={true}
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                autoComplete="family-name"
                                                className="mt-1 col-span-6 sm:col-span-3 p-2  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            <div className={'text-lg text-right flex justify-end'}>
                                                {isValidLastName ?
                                                    <CheckIcon className={'h-6 w-6'} color={'green'}/> :
                                                    <span className={'text-red-600 font-bold text-3xl h-5'}> *</span>}
                                            </div>
                                        </div>
                                        }
                                        {/*education specialization*/}
                                        {(props.targetGroup === '1' || props.targetGroup === 1) &&
                                        <div className="col-span-6 flex grid grid-cols-7">
                                            <label htmlFor="gender"
                                                   className="block col-span-7 sm:col-span-2 text-sm font-medium flex items-center">
                                                {getEducationSpecText(props.language)}
                                            </label>
                                            <select
                                                required={true}
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    addPropertyToUser(event.target)
                                                    validateEducationSpec(event.target.value)
                                                }}
                                                id="educationSpecialization"
                                                name="educationSpecialization"
                                                autoComplete="off"
                                                className="mt-1 col-span-6 sm:col-span-3 block w-full py-2 px-3 pr-8 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none  sm:text-sm"
                                            >
                                                <option value={''}>{getSelectText(props.language)}</option>
                                                <option value={'Yrkesfag'}>Yrkesfag</option>
                                                <option value={'Studiespesialisering'}>Studiespes</option>
                                            </select>
                                            <div className={'text-lg text-right flex justify-end'}>
                                                {isValidEducationSpec ?
                                                    <CheckIcon className={'h-6 w-6'} color={'green'}/> :
                                                    <span className={'text-red-600 font-bold text-3xl h-5'}> *</span>}
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>

                                <div className={'p-3 overflow-hidden '}>
                                    <div className={'bg-white p-5 rounded-xl border border-gray-300'}>

                                        <div className={'font-bold'}>{getConsentHeadingText(props.language)} <span
                                            className={'text-red-600 text-lg py-0 pl-2 h-5'}> * </span></div>

                                        <label htmlFor="agree" className={'cursor-pointer flex'}>
                                            <input type="checkbox" className={"invalid:ring-2 ring-green-500"}
                                                   id={'agree'}
                                                   required
                                                   defaultChecked={false}
                                                   defaultValue={false}
                                                   onChange={(event) => {
                                                       console.log('');
                                                       setIsTermsAgreed(event.target.checked);
                                                   }}/>
                                            <span className={'px-2'}><p
                                                className={"text-sm"}>{getConsentText(props.language)}</p></span>
                                        </label>
                                        <div onClick={(event => {
                                            event.preventDefault();
                                            setSeeMore(!seeMore);
                                        })}
                                             className={'cursor-pointer hover:underline text-sm text-blue-400'}>{getSeeMoreText(props.language)}...
                                        </div>
                                        <p
                                            className={`${seeMore ? "text-sm" : 'hidden'}`}>{getConsentDetailedText(props.language)}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div
                                className={`px-4 py-3 ${props.rightAligned ? 'text-left' : 'text-right'} sm:px-6 border-t-2`}>
                                <button
                                    type="submit"
                                    onClick={event => {
                                        event.preventDefault();
                                        if (isFormValid)
                                            props.setUser(user)
                                        else
                                            document.getElementById('mobile').focus()
                                    }}
                                    // disabled={!isFormValid}
                                    className={`button-default ${isFormValid ? 'button-gradient enabled' : 'button-gradient-disabled cursor-pointer'}`}
                                >
                                    {getNextButtonText(props.language)}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserForm