import React, { useReducer, useState} from "react";
import {connect} from "react-redux";
import {addAnswerToSurveyResponse, postSurvey} from "../reducer";
import Question from "./shared/Question";
import {storeIgnoreActivity} from "../reducer";
import {
    getActivityTextDescription,
    getActivityTextHeading,
    getNextButtonText,
    getPreviousButtonText,
    getSubmitButtonText
} from "./util/Translations";
const QuestionContainer = (props) => {

    const params = props.params;
    const module = props.module;
    const [answeredQuestions, setAnsweredQuestions] = useState({})
    const [count, forceUpdate] = useReducer(x => x + 1, 0);
    const [currentPage, setCurrentPage] = useState(0)
    const [ignoreActivityQuestions, setIgnoreActivityQuestions] = useState(false)
    let rendered = {};
    let surveyQuestions;
    let connectedQuestion = {};
    let renderedQuestionsIds = {};

    function populateAnsweredQuestions(questionId, anserValue) {
        let a = answeredQuestions;
        a[questionId] = anserValue;
        setAnsweredQuestions(a);
        console.log(answeredQuestions)
        forceUpdate()

    }

    function getQuestions(module) {

        let sqPages = [];

        surveyQuestions = module.questions.filter(q=> q.gender!==null?q.gender === props.gender:1).sort((a, b) => {
            if (a.sortOrder > b.sortOrder)
                return 1
            if (a.sortOrder === b.sortOrder)
                return 0
            if (b.sortOrder > a.sortOrder)
                return -1
            return 0;
        });

        for (let qPageIndex = 0; qPageIndex < surveyQuestions.length; qPageIndex += props.questionsPerPage) {
            sqPages.push(<ul id={`current-page-${qPageIndex}`} key={qPageIndex}
                             className={currentPage !== qPageIndex ? 'hidden' : ''}>
                {surveyQuestions.map((question, index) => {
                        let renderQuestion = true;
                        if (question.parentQuestionId !== null) {
                            if (!answeredQuestions[question.parentQuestionId])
                                renderQuestion = false;
                            if (question.parentQuestionEvaluator.operator === 'lt' && answeredQuestions[question.parentQuestionId] >= question.parentQuestionEvaluator.limit)
                                renderQuestion = false;
                            if (question.parentQuestionEvaluator.operator === 'gt' && answeredQuestions[question.parentQuestionId] <= question.parentQuestionEvaluator.limit)
                                renderQuestion = false;
                            if (question.parentQuestionEvaluator.operator === 'e' && answeredQuestions[question.parentQuestionId] !== question.parentQuestionEvaluator.limit)
                                renderQuestion = false;
                        }
                        if (renderQuestion) {
                            let ids = renderedQuestionsIds && renderedQuestionsIds[qPageIndex] ? renderedQuestionsIds[qPageIndex] : [];
                            if (index >= qPageIndex && index < qPageIndex + props.questionsPerPage) {
                                ids.push(question.id)
                            }
                            renderedQuestionsIds[qPageIndex] = ids;
                            rendered[question.id] = true;
                            return <Question question={question} key={question.id} surveyResponse={props.surveyResponse}
                                             language={props.language}
                                             active={currentPage === qPageIndex}
                                             activeTab = {props.active}
                                             qPage={qPageIndex}
                                             currentPage={currentPage}
                                             answeredQuestions={answeredQuestions}
                                             answered={populateAnsweredQuestions}/>
                        } else {
                            if (connectedQuestion && connectedQuestion[qPageIndex])
                                connectedQuestion[qPageIndex] = connectedQuestion[qPageIndex] + 1
                            else
                                connectedQuestion[qPageIndex] = 1
                            return null
                        }

                    }
                ).splice(qPageIndex, props.questionsPerPage)}</ul>)
        }

        sqPages = sqPages.filter(p => p.props.children !== null)
        return sqPages

    }

    function getDisabled() {

        let disabled = true;
        let unAnswered = false;

        if (renderedQuestionsIds && renderedQuestionsIds[currentPage]) {
            renderedQuestionsIds[currentPage].map((id) => {
                if (!unAnswered && props.surveyResponse && props.surveyResponse[id] && props.surveyResponse[id].length>0)
                    disabled = false;
                else {
                    disabled = true;
                    unAnswered = true;
                }
            });
        }

        return disabled

    }

    function answeredAll() {
        let all = true;
        Object.keys(rendered).map(q => {
            if (!Object.keys(answeredQuestions).includes(q))
                all = false;
        })
        return all;
    }

    function getNextPage() {
        let next = true;
        let nexPage = currentPage + props.questionsPerPage;
        let count = Object.keys(renderedQuestionsIds).length;
        while (next && count > 0) {
            if (renderedQuestionsIds[nexPage] && renderedQuestionsIds[nexPage].length > 0)
                next = false;
            else
                nexPage = nexPage + props.questionsPerPage;
            count--;

        }

        return nexPage;
    }

    function getPreviousPage() {
        let prev = true;
        let prevPage = currentPage - props.questionsPerPage;
        let count = Object.keys(renderedQuestionsIds).length;
        while (prev && count > 0) {
            if (renderedQuestionsIds[prevPage] && renderedQuestionsIds[prevPage].length > 0)
                prev = false;
            else
                prevPage = prevPage - props.questionsPerPage;
            count--;

        }
        return prevPage;
    }


    function alterIgnore(val) {
        setIgnoreActivityQuestions(val)
        props.storeIgnoreActivity(val)
    }
    function scrollTop() {
        console.log('scrolling to top');
        window.scrollTo(0, 0);
        console.log('scrolled to top');
    }

    return (
        <div id={props.module.name} className={`py-2 divide-y divide-gray-100 ${props.active ? "" : "hidden"}`}>
            {(props.module && props.module.category === 'ACTIVITY' && currentPage === 0) &&
            <div className="px-6 sm:px-20 py-12">
                <label className="ignoreQuestionsFromActivityModule ">
                    <input type="checkbox" name="ignoreQuestionsFromActivityModuleSelector"
                           id="ignoreQuestionsFromActivityModuleSelector"
                           onChange={(event) => {
                               alterIgnore(event.target.checked);
                           }}/>
                    &nbsp;
                    <span className={'font-bold'}>{getActivityTextHeading(props.language)}</span>
                    <p className="survey-text-activity">{getActivityTextDescription(props.language)}</p>
                </label>
            </div>
            }


            {!ignoreActivityQuestions && getQuestions(module)}

            <div className={'mt-8 dark-grey flex justify-between py-3.5 px-2 sm:px-20 '}>
                {currentPage > 0 || props.activeTab > 0 ?
                    <button className={'button-gradient button-default text-lg sm:text-xl'} onClick={event => {
                        event.preventDefault();
                        if (ignoreActivityQuestions && props.activeTab>0)
                            props.setActiveTab(props.activeTab-1)
                        if (currentPage > 0)
                            setCurrentPage(getPreviousPage())
                        if (currentPage === 0 && props.activeTab > 0)
                            props.setActiveTab(props.activeTab - 1)
                        document.getElementById('modules-nav').scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "nearest"
                        })
                    }}>
                        {getPreviousButtonText(props.language)}
                    </button> : <span> </span>

                }
                {(surveyQuestions && currentPage < surveyQuestions.length - props.questionsPerPage) ||
                props.activeTab < props.totalModule - 1 ?
                    <button
                        className={`${(getDisabled() && !ignoreActivityQuestions) ? 'button-gradient-disabled' : 'button-gradient enabled'} button-default text-lg sm:text-xl`}
                        disabled={(getDisabled() && !ignoreActivityQuestions)}
                        onClick={(event) => {
                            event.preventDefault();

                            if (ignoreActivityQuestions) {
                                props.setActiveTab(props.activeTab + 1)
                            } else {
                                if (surveyQuestions && currentPage < surveyQuestions.length - props.questionsPerPage) {
                                    let nextPage = getNextPage()
                                    if (renderedQuestionsIds[nextPage] && renderedQuestionsIds[nextPage].length > 0) {
                                        setCurrentPage(nextPage)

                                    } else {
                                        if (props.activeTab < props.totalModule - 1) {
                                            props.setActiveTab(props.activeTab + 1)
                                        }
                                    }
                                }
                                if (currentPage + props.questionsPerPage >= surveyQuestions.length && answeredAll() && props.activeTab < props.totalModule - 1) {
                                    props.setActiveTab(props.activeTab + 1)
                                }
                            }

                            document.body.scrollTop = 0;
                            scrollTop();
                            document.getElementById('modules-nav').scrollIntoView({
                                behavior: "smooth",
                            })

                        }}>
                        {getNextButtonText(props.language)}
                    </button> :

                    <button
                        className={`${getDisabled() ? 'button-gradient-disabled' : 'button-gradient enabled'} button-default text-lg sm:text-xl`}
                        onClick={event => {
                            event.preventDefault();
                            props.postSurvey(params.surveyId, ignoreActivityQuestions,props.language);
                        }}>
                        {getSubmitButtonText(props.language)}
                    </button>
                }
            </div>
        </div>
    )
};
const mapStateToProps = ({surveyResponse}) => {
    return {
        surveyResponse: surveyResponse
    }
}
export default connect(mapStateToProps, {addAnswerToSurveyResponse, postSurvey, storeIgnoreActivity})(QuestionContainer)
