import {getStartButtonText, getTypePasswordText} from "../util/Translations";
import React, {useState} from "react";

const PasswordForm = (props) => {

    const [key, setKey] = useState('');
    return (
        <div className={'w-full'}>
            <form className={'flex flex-col justify-center items-center'}
                  onSubmit={(event) => {
                      event.preventDefault();
                      if (key.trim() !== '')
                          props.setLicenseKey(key);
                  }}>
                <label className={'w-full my-3'}>
                                                    <span
                                                        className={'text-xl py-2 w-full font-bold flex justify-center items-center'}>{getTypePasswordText(props.surveyLang)}</span>
                    <input type="text"
                           className={'text-center w-full rounded-xl p-1 pl-2 text-3xl focus:border-green-400 outline-none'}
                           value={key}
                           onChange={(event => {
                               setKey(event.target.value)
                           })}/>
                </label>
                <button type="submit" value="Submit"
                        className={'my-3 button-gradient enabled hover:text-white text-xl button-default w-full font-semibold'}>{getStartButtonText(props.surveyLang)}
                </button>
            </form>
        </div>
    )
}
export default PasswordForm