//type
export const ADD_ANSWER_TO_RESPONSE = 'ADD_ANSWER_TO_RESPONSE';
export const POST_RESPONSE = 'POST_RESPONSE';
export const STORE_KEY = 'STORE_KEY';
export const STORE_RESPONSE_ID = 'STORE_RESPONSE_ID';
export const STORE_PERSON_ID = 'STORE_PERSON_ID';
export const STORE_IGNORE_ACTIVITY = 'STORE_IGNORE_ACTIVITY';
export const CATCH_ERROR = 'CATCH_ERROR';
export const SHOW_ERROR_ALERT = 'SHOW_ERROR_ALERT';
export const GET_REQUEST_PENDING = "GET_REQUEST_PENDING";
export const SHOW_REQUEST_PASSWORD = "SHOW_REQUEST_PASSWORD";
export const PASSWORD_REQUESTED = "PASSWORD_REQUESTED";

//actions
export const addAnswer = (responseAnswers) => ({type: ADD_ANSWER_TO_RESPONSE, payload: responseAnswers});
export const postResponse = (posted) => ({type: POST_RESPONSE, payload: posted});
export const catchError = (error) => ({type: CATCH_ERROR, payload: error});
export const showErrorAlert = (error) => ({type: SHOW_ERROR_ALERT, payload: error});
export const storeKeyAction = (key) => ({type: STORE_KEY, payload: key});
export const storePersonIdAction = (personId) => ({type: STORE_PERSON_ID, payload: personId});
export const storeResponseIdAction = (responseId) => ({type: STORE_RESPONSE_ID, payload: responseId});
export const storeIgnoreActivityAction = (ignore) => ({type: STORE_IGNORE_ACTIVITY, payload: ignore});
export const getRequestPending=(pending)=>({type:GET_REQUEST_PENDING,payload:pending})
export const showRequestPasswordPopup=(show)=>({type:SHOW_REQUEST_PASSWORD,payload:show})
export const passwordRequested=(requested)=>({type:PASSWORD_REQUESTED,payload:requested})