import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Dialog, Transition} from "@headlessui/react";
import {KeyIcon} from "@heroicons/react/outline";
import {alterRequestPasswordComponentState, requestPassword} from "../../reducer";
import {
    getCancelButtonText,
    getMobileText, getPasswordSentConfirmText,
    getPasswordText,
    getSendPasswordText,
    getWriteMobileText
} from "../util/Translations";

class RequestPasswordPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {mobile: ''};
    }

    render() {
        return (<div>
            <Transition.Root show={this.props.requestPasswordShow
            } as={Fragment} onClose={() => {
                this.props.alterRequestPasswordComponentState(false)
            }}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div
                                            className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-50 sm:mx-0 sm:h-10 sm:w-10">
                                            <KeyIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg leading-6 font-medium text-gray-900">
                                                {getPasswordText(this.props.lang)}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                {!this.props.passwordSent &&
                                                <div className={'flex flex-col'}>
                                                <p className="text-sm">
                                                    <strong>{getWriteMobileText(this.props.lang)}</strong>
                                                </p>
                                                    <label htmlFor="mobile">{getMobileText(this.props.lang)}*</label>
                                                    <input className={'border border-gray-600 p-2 mt-2'} type="mobile"
                                                           onChange={(event) => {
                                                               this.setState({mobile: event.target.value})
                                                           }}/>
                                                    <button className={'bg-blue-500 text-white rounded mt-2 p-2'}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                this.props.requestPassword(this.props.surveyId, this.state.mobile)
                                                            }}>{getSendPasswordText(this.props.lang)}
                                                    </button>
                                                </div>
                                                }{this.props.passwordSent && <p>{getPasswordSentConfirmText(this.props.lang)}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            this.props.alterRequestPasswordComponentState(false)
                                        }
                                        }
                                    >
                                        {getCancelButtonText(this.props.lang)}
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>)
    }
}

const mapStateToProps = ({passwordSent, requestPasswordShow}) => {
    return {
        requestPasswordShow: requestPasswordShow,
        passwordSent: passwordSent
    }
}
export default connect(mapStateToProps, {alterRequestPasswordComponentState, requestPassword})(RequestPasswordPopup)