import {EmojiSadIcon, XIcon} from '@heroicons/react/outline'
import {connect} from "react-redux";
import {alterErrorAlert} from "../../reducer";
import {useEffect} from "react";

const BannerAlert = (props) => {
    useEffect(()=>{
        if (props.showErrorAlert)
            document.getElementById('alert-banner').scrollIntoView({behavior:"smooth"})
    })
    if (props.showErrorAlert)
        return (

            <div className="bg-red-600" id={'alert-banner'}>
                <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-red-600">
              <EmojiSadIcon className="h-6 w-6 text-white" aria-hidden="true"/>
            </span>
                            <p className="ml-3 font-medium text-white truncate">
                                <span className="md:hidden">Something Went Wrong</span>
                                <span
                                    className="hidden md:inline">ERROR! Something Went Wrong...</span>
                            </p>
                        </div>
                        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.alterErrorAlert(false)

                                }}
                                className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                            >
                                <span className="sr-only">Dismiss</span>
                                <XIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        )
    else
        return null;
}
const mapStateToProps = ({showErrorAlert}) => {
    return {
        showErrorAlert: showErrorAlert,
    }
};
export default connect(mapStateToProps,{alterErrorAlert})(BannerAlert)
