import React, {useLayoutEffect} from "react";
import {connect} from "react-redux";
import {addAnswerToSurveyResponse, removeAnswerFromSurveyResponse} from "../../reducer";
import VideoQuestion from "./VideoQuestion";
import {getSelectText} from "../util/Translations";
import QuestionImage from "./QuestionImage";

const Question = (props) => {
    const question = props.question;

    useLayoutEffect(() => {
        return () => {
            if (props.surveyResponse[question.id])
                props.removeAnswerFromSurveyResponse(question.id)
        }
    }, [props.surveyResponse, question.id])

    function getChoices() {
        let choices = question.choices.sort((a, b) => {
            if (a.sortOrder > b.sortOrder)
                return 1
            if (a.sortOrder === b.sortOrder)
                return 0
            if (b.sortOrder > a.sortOrder)
                return -1
            return 0;
        })
        if (question.renderer === 'radiobuttons')
            return choices.map(choice =>
                <div key={choice.id} className={'cursor-pointer'}>
                    <input type={'radio'} id={choice.id}
                           className={""}
                           name={question.id}
                           checked={(props.surveyResponse && props.surveyResponse[question.id] && props.surveyResponse[question.id].includes(choice.id)) || false}
                           onChange={(event => {
                               console.log(choice.id)
                           })}
                           onClick={(() => {
                               let answer = [];
                               answer.push(choice.id)
                               props.answered(question.id, choice.value);
                               props.addAnswerToSurveyResponse(question.id, answer)
                           })}
                    />
                    <label htmlFor={choice.id} className={'cursor-pointer px-1'}>{choice.text}</label>
                </div>)
        if (question.renderer === 'buttons')
            return choices.map(choice =>
                <button key={choice.id} onClick={(event => {
                    event.preventDefault();
                    let answer = [];
                    answer.push(choice.id)
                    props.answered(question.id, [choice.value]);
                    props.addAnswerToSurveyResponse(question.id, answer)
                })}
                        className={`button-gradient button-radio px-2  ${question.choices && question.choices.length === 6 && choice.text!=null && choice.text.length > 8 ? 'text-sm break-words' : 'text-sm'}  ${props.surveyResponse[question.id] && props.surveyResponse[question.id].includes(choice.id) ? 'checked' : ""}`}>
                    {choice.text}
                </button>)

        if (question.renderer === 'CHECKBOX') {
            return choices.map(choice =>
                <div key={choice.id} className={'cursor-pointer'}>
                    <input type={'checkbox'} id={choice.id}
                           key={choice.id}
                           className={""}
                           name={question.id}
                           checked={(props.surveyResponse && props.surveyResponse[question.id] && props.surveyResponse[question.id].includes(choice.id)) || false}
                           onChange={(event => {
                               let answers = (props.surveyResponse && props.surveyResponse[question.id]) ? props.surveyResponse[question.id] : [];
                               if (event.target.checked)
                                   answers.push(choice.id)
                               else {
                                   for (let i = 0; i < answers.length; i++) {
                                       if (answers[i] === choice.id)
                                           answers.splice(i, 1)
                                   }
                               }
                               props.answered(question.id, choice.value);
                               props.addAnswerToSurveyResponse(question.id, answers);
                           })}
                           onClick={(() => {

                           })}
                    />
                    <label htmlFor={choice.id} className={'cursor-pointer px-1'}>{choice.text}</label>
                </div>)
        }
        if (question.renderer === 'DROPDOWN') {
            return <select name={question.id} id={question.id} onChange={((event) => {
                props.answered(question.id, event.target.value);
                let answer = [];
                if (event.target.value !== "")
                    answer.push(event.target.value)
                props.addAnswerToSurveyResponse(question.id, answer)
            })}>
                <option value="">{getSelectText(props.language)}</option>
                {
                    choices.map(choice =>
                        <option
                            key={choice.id}
                            selected={(props.surveyResponse && props.surveyResponse[question.id] && props.surveyResponse[question.id].includes(choice.id)) || false}
                            value={choice.id}>
                            {choice.text}
                        </option>)
                }</select>
        }
    }


    function shouldRenderInColumn(choices) {

        let renderInColumn = true;
        if(choices!=null) {
            choices.forEach(c => {
                if (c.text != null && c.text.length >= 10) renderInColumn = false
            })
        }

        return renderInColumn;
    }

    return (
        <div className={'flex flex-col  bg-gray-100 my-1'}>
            {(question.media.length > 0 && question.media[0].type === 'vimeo' && question.media[0].url) &&
            <VideoQuestion autoplay={props.active} activeTab={props.activeTab} videoId={question.media[0].url} qPage={props.qPage} currentPage={props.currentPage} page={question.id}/>}
            {(question.media.length > 0 && question.media[0].type === 'picture') &&
            <QuestionImage src={question.media[0].id} id={question.id}/>}
            <div
                className={'py-2 px-4 sm:px-20 py-12 flex flex-col lg:flex-row '}>

                <div className={'w-full lg:w-2/3 lg:mr-3 pl-3'}>
                    <p className={'font-bold text-2xl'}> {question.text}</p>
                    <p className={'mt-4 pb-2 whitespace-pre-wrap'} > {question.description}</p>
                </div>
                <div className={'w-full lg:w-1/3'}>
                    <div className={` grid p-8 dark-grey items-center rounded-xl
                ${(question.choices !=null && question.choices.length === 6 && question.surveyRenderType !== 'radiobuttons' && shouldRenderInColumn(question.choices)) ? 'grid-flow-row grid-cols-3 grid-rows-2 ' : ''} gap-1`}>
                        {getChoices()}
                    </div>
                </div>

            </div>
        </div>


    )
}

const mapStateToProps = ({error}) => {
    return {
        error: error
    }
}
export default connect(mapStateToProps, {addAnswerToSurveyResponse, removeAnswerFromSurveyResponse})(Question);
