import React, {useState} from "react";
import QuestionContainer from "./QuestionContainer";
import {connect} from "react-redux";
import {postSurvey} from "../reducer";
import {useParams} from "react-router-dom";
import {getUserDefinedModuleText} from "./util/Translations";

const ModuleContainer = (props) => {
    const modules = props.modules;
    const params = useParams()
    const [activeTab, setActiveTab] = useState(0);
    let totalModules = 0;

    function getModules() {
        return modules.filter(m => m.questions.length > 0).map((module, index) => {

                totalModules++;

                return <li key={module.id}
                           className={`px-4  flex ${index===0 ?'pl-10':''} nav-path font-bold text-center border-r-2 border-gray-400 items-center ${index <= activeTab ? 'active-path' : ""}`}>
                    <span className={`${index===activeTab?'hidden':''} md:hidden`}>{index+1}</span>
                    <span className={` md:block ${index ===activeTab? 'block':'hidden'} `}>{module.userDefined?getUserDefinedModuleText(props.language):module.name}</span>
                </li>
            }
        );
    }

    return (
        <div className={'pb-4'}>
            {/*Tabs*/}
            <div className={'path-line'}>
                <nav id={'modules-nav'} className={`m-0 nav-path dark-grey ${props.rightAligned?'sm:-mr-6':'sm:-ml-6'}`}>
                    <ul className={'flex flex-wrap  sm:h-full dark-grey '+props.gender}>
                        {getModules()}
                    </ul>
                    <div className={`hidden sm:block corner ${props.rightAligned?'flip-corner':''}`}></div>
                </nav>
            </div>

            {/*Questions for each tab*/}
            {
                modules.filter(m => m.questions.length > 0).map((module, index) =>
                    <QuestionContainer key={module.id} module={module}
                                       activeTab={activeTab}
                                       params={params}
                                       gender={props.gender}
                                       language={props.language}
                                       totalModule={totalModules}
                                       setActiveTab={setActiveTab}
                                       active={activeTab === index}
                                       questionsPerPage={props.questionsPerPage>0?props.questionsPerPage:3}/>
                )
            }
        </div>
    )
}
const mapStateToProps = ({error}) => {
    return {
        error: error
    }
}
export default connect(mapStateToProps, {postSurvey})(ModuleContainer)