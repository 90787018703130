export const getNextButtonText = (lang) => {
    switch (lang) {
        case "no":
            return "Neste"
        case "en":
            return "Next"
        case "so":
            return "Ku xiga"
        case "fa":
            return "صفحه بعد"
        case "ar":
            return "التالي"
        default:
            return "Neste";

    }
}

export const getPreviousButtonText = (lang) => {
    switch (lang) {
        case "no":
            return "Tilbake"
        case "en":
            return "Back"
        case "so":
            return "Dib u noqo"
        case "fa":
            return "صفحه قبل"
        case "ar":
            return "رجوع"
        default:
            return "Neste";

    }
}
export const getStartButtonText = (lang) => {
    switch (lang) {
        case "no":
            return "Start testen"
        case "en":
            return "Start the test"
        case "so":
            return "Bilow imtixaanka"
        case "fa":
            return "ورود به ارزیابی"
        case "ar":
            return "ابدأ الاختبار"
        default:
            return "Start testen";

    }
}
export const getSubmitButtonText = (lang) => {
    switch (lang) {
        case "no":
            return "Til ditt resultat"
        case "en":
            return "See Results"
        case "so":
            return "Natiijadaada"
        case "fa":
            return "رفتن به سنجش و ارزیابی خود"
        case "ar":
            return "إلى نتيجتك"
        default:
            return "Til ditt resultat";

    }
}
export const getTypePasswordText = (lang) => {
    switch (lang) {
        case "no":
            return "Skriv inn ditt passord"
        case "en":
            return "Type in your password"
        case "so":
            return "Ku qor eraygaaga sirta ah"
        case "fa":
            return "پسورد خود را وارد کنید"
        case "ar":
            return "ادخل كلمة المرور"
        default:
            return "Skriv inn ditt passord";

    }
}

export const getFooterText = (lang) => {
    switch (lang) {
        case "no":
            return "Min Livsstil © er rettighetsbeskyttet etter bl.a. lov om opphavsrett til åndsverk m.m. Design og\n" +
                "                    sammenstillinger kan derfor ikke kopieres, reproduseres, re-publiseres, lastes ned eller på noen\n" +
                "                    annen måte gjengis uten etter skriftlig godkjenning fra Enforme AS. Kopiering i strid med Enforme AS sine\n" +
                "                    rettigheter kan medføre erstatnings- og/eller straffansvar."
        case "en":
            return "My Lifestyle © is protected by rights according to e.g. law on copyright to intellectual property, etc. Design and\n" +
                "                     compilations can therefore not be copied, reproduced, republished, downloaded or downloaded\n" +
                "                     other way is reproduced without after written approval from Enforme AS. Copying in violation of Enforme AS's\n" +
                "                     rights may entail liability and / or criminal liability."
        default:
            return "Min Livsstil © er rettighetsbeskyttet etter bl.a. lov om opphavsrett til åndsverk m.m. Design og\n" +
                "                    sammenstillinger kan derfor ikke kopieres, reproduseres, re-publiseres, lastes ned eller på noen\n" +
                "                    annen måte gjengis uten etter skriftlig godkjenning fra Enforme AS. Kopiering i strid med Enforme AS sine\n" +
                "                    rettigheter kan medføre erstatnings- og/eller straffansvar.";

    }
}
export const getUserFormHeadingText = (lang) => {
    switch (lang) {
        case "no":
            return "Påloggingsinformasjon"
        case "en":
            return "Login information"
        case "so":
            return "Macluumaadka gelitaanka"
        case "fa":
            return "اطلاعات ورود به سیستم"
        case "ar":
            return "معلومات تسجيل الدخول"
        default:
            return "Påloggingsinformasjon";

    }
}
export const getConsentHeadingText = (lang) => {
    switch (lang) {
        case "no":
            return "Samtykkeerklæring"
        case "en":
            return "Declaration of consent"
        case "so":
            return "Bayaanka ogolaanshaha"
        case "fa":
            return "توافق نامه"
        case "ar":
            return "اقرارالموافقة"
        default:
            return "Samtykkeerklæring";

    }
}
export const getConsentDetailedText = (lang) => {
    switch (lang) {
        case "no":
            return "All registrert informasjon lagres, beskyttes og behandles fortrolig i tråd med gjeldende lov (GDPR). Du samtykker videre til at dine opplysninger kan oppbevares inntil 5 år. Dataene dine lagres innenfor EØS. Informasjonen du oppgir er som del av en større gruppe og du samtykker til at oppdragsgiver anonymisert kan ta ut rapporter som viser overordnede resultater og trender på gruppenivå og kan brukes til forskningsformål. Du kan når som helst be om sletting, dette gjøres med en skriftlig henvendelse til Databehandler via post (Enforme AS, Pb 8034, 4068 Stavanger) eller e-post (post@enforme.no)."
        case "en":
            return "All registered information is stored, protected and treated confidentially in accordance with applicable law (GDPR). You further agree that your information may be stored for up to 5 years. Your data is stored within the EEA. The information you provide is part of a larger group and you agree that the client can anonymously extract reports that show overall results and trends at group level and can be used for research purposes. You can request deletion at any time, this is done with a written inquiry to Databehandler via post (Enforme AS, Pb 8034, 4068 Stavanger) or e-mail (post@enforme.no)."
        case "so":
            return "Warbixinta aad bixisay waxay qayb ka tahay warbixino kale oo faro badan. Waxaad ogolaatay in loo " +
                "shaqeeyuhu, iyadoo qofna la aqoonsan uu la soo bixi karo, xog muujinaysa natiijooyinka guud ee heererka kala duwan, loona isticimaali karo ujeedooyin cilmi- baaris. Wakhti kasta waxaad codsan " +
                "kartaa in la tirtiro xogtaadda. Waxaad soo qoraysaa codsi qoraal ah oo ku socoda qaybta kaydinta " +
                "adigoo ku soo hagaajinaaya sanduuqa boosta (Enforme AS, Pb 8034, 4068 Stavanger) ama iimaylkan " +
                "(post@enforme.no)."
        case "fa":
            return "اطلاعات ارائه شده از طرف شما به صورت قسمتی از یک دسته بزرگ‌تری از اطلاعات می باشد و شما موافقت می نمایید که متقاضیانِ این نوع اطلاعات این اجازه را خواهند داشت که به صورت ناشناس گزارشاتی را که نتایج و‌ روندهای ذکر شده در بالا و در سطح گروه و دسته بزرگ‌تر را نشان می‌دهد، دریافت کنند و به منظور تحقیقات به کار برند. شما می توانید هر زمانی که مایل بودید تقاضای پایان همکاری و حذف اطلاعات خود را بدهید، برای این کار می توانید یک درخواست کتبی به بخش پردازش داده بفرستید که این از طریق پست (به آدرس: Enforme AS, Pb 8034, 4068 Stavanger) و یا پست الکترونیکی (post@enforme.no) انجام پذیر است."
        case "ar":
            return "المعلومات التي تقدمها هي جزء من مجموعة متكاملة. أنت توافق على أنه يمكن لصاحب العمل سحب التقارير دون اظهار هوية المشترك. هذه التقارير تظهر النتائج العامة والاتجاهات على مستوى المجموعة ويمكن استخدامها لأغراض البحث. " +
                " يمكنك طلب الحذف في أي وقت. يتم ذلك من خلال تقديم  طلب خطي إلى معالج البيانات عبر البريد  (Enforme AS ، Pb 8034 ، 4068 Stavangern " +
                " أو البريد الإلكتروني .(post@enforme.no)"
        default:
            return "Det er frivillig å gjennomføre egenvurderingen ”Min Livsstil” og du samtykker til oppbevaring av registrerte data. All registrert informasjon lagres, beskyttes og behandles fortrolig i tråd med gjeldende lov (GDPR). Du samtykker videre til at dine opplysninger kan oppbevares inntil 5 år. Dataene dine lagres innenfor EØS. Informasjonen du oppgir er som del av en større gruppe og du samtykker til at oppdragsgiver anonymisert kan ta ut rapporter som viser overordnede resultater og trender på gruppenivå og kan brukes til forskningsformål. Du kan når som helst be om sletting, dette gjøres med en skriftlig henvendelse til Databehandler via post (Enforme AS, Pb 8034, 4068 Stavanger) eller e-post (post@enforme.no).";

    }
}
export const getConsentText = (lang) => {
    switch (lang) {
        case "no":
            return "Det er frivillig å gjennomføre egenvurderingen ”Min Livsstil” og du samtykker til oppbevaring av registrerte data."
        case "en":
            return "It is voluntary to carry out the self-assessment \"My Lifestyle\" and you agree to the storage of registered data. "
        case "so":
            return "Waxaad xor u tahay ah inaad dhamays tirto is-qiimayntan &quot; Min Livsstil &quot; waxaad ogolaatay in la " +
                "kaydiyo xogtan la diiwaan geliyey. Dhamaan xogtan diiwaangashan waa la kaydinayaa, la ilaalinayaa " +
                "oo loola dhaqmayaa si qarsoodi ah oo waafaqsan sharciga khuseeya (GDPR). Waxaad kaloo " +
                "ogolaatay in xogtaada la kaydin karo ilaa 5 sano. xogtan waxaa lagu kaydinayaa Norway oo dibadda " +
                "looma gudbinaayo."
        case "fa":
            return "ارزیابی فردی \"سبک زندگی من\" داوطلبانه می باشد و شما مستلزم به حفظ و نگهداری از اطلاعات موجود هستید. در راستای قانون موجود (GDPR)، تمام اطلاعات ثبت شده به طور محرمانه ذخیره، حفظ و مورد بررسی قرار می گیرند. شما همچنین موافقت می کنید که اطلاعات شما می تواند تا ۵ سال ذخیره شود. تمام اطلاعات شما در کشور نروژ ذخیره می شود و قابل انتقال به کشورهای دیگر نخواهد بود."
        case "ar":
            return "يُعد إجراء التقييم الذاتي \"Min Livsstil\" أمرًا طوعياً وأنت توافق على تخزين البيانات المسجلة. يتم تخزين جميع المعلومات المسجلة وحمايتها ومعالجتها بسرية وفقًا للقانون المعمول به (GDPR). أنت توافق أيضًا على أنه قد يتم تخزين معلوماتك لمدة تصل إلى 5 سنوات. يتم تخزين معلوماتك في النرويج ولا يتم نقلها إلى الخارج."
        default:
            return "Det er frivillig å gjennomføre egenvurderingen ”Min Livsstil”, men vi gjør oppmerksom på at du ved å gå videre og begynne besvarelse av undersøkelsen samtykker til oppbevaring av registrerte data som følger:";

    }
}

export const getFirstNameText = (lang) => {
    switch (lang) {
        case "no":
            return "Fornavn"
        case "en":
            return "First name"
        case "so":
            return "Magaca koowaad"
        case "fa":
            return "نام"
        case "ar":
            return "الاسم"
        default:
            return "Fornavn";

    }
}

export const getLastNameText = (lang) => {
    switch (lang) {
        case "no":
            return "Etternavn"
        case "en":
            return "Surname"
        case "so":
            return "Magaca qoyska"
        case "fa":
            return "نام خانوادگی"
        case "ar":
            return "العائلة"
        default:
            return "Etternavn";

    }
}

export const getBirthText = (lang) => {
    switch (lang) {
        case "no":
            return "Født(åååå)"
        case "en":
            return "Born (yyyy)"
        case "so":
            return "Dhashay"
        case "fa":
            return "سال تولد"
        case "ar":
            return "سنة الولادة"
        default:
            return "Født(åååå)";

    }
}
export const getMobileText = (lang) => {
    switch (lang) {
        case "no":
            return "Mobilnummer"
        case "en":
            return "Mobile"
        case "so":
            return "Nambarka taleefonka gacanta"
        case "fa":
            return "شماره موبایل"
        case "ar":
            return "رقم الهاتف"
        default:
            return "Mobilnummer";

    }
}
export const getEmailText = (lang) => {
    switch (lang) {
        case "no":
            return "Epost"
        case "en":
            return "Email"
        default:
            return "Epost";

    }
}
export const getSubGroupTextClass = (lang) => {
    switch (lang) {
        case "no":
            return "Klasse"
        case "en":
            return "Class"
        case "so":
            return "Fasalka"
        case "fa":
            return "گروه"
        case "ar":
            return "فئة"
        default:
            return "";

    }
}
export const getSubGroupText = (lang) => {
    switch (lang) {
        case "no":
            return "Gruppe"
        case "en":
            return "Group"
        case "so":
            return "Koox"
        case "fa":
            return "گروه"
        case "ar":
            return "المجموعة"
        default:
            return "";

    }
}
export const getEducationSpecText = (lang) => {
    switch (lang) {
        case "no":
            return "Studieretning"
        case "en":
            return "Field of study"
        case "so":
            return "Nooca waxbarshadda"
        case "fa":
            return "رشته تحصیلی"
        case "ar":
            return "مجال الدراسة"
        default:
            return "Studieretning";

    }
}
export const getGenderText = (lang) => {
    switch (lang) {
        case "no":
            return "Kjønn"
        case "en":
            return "Gender"
        case "so":
            return "Jinsi"
        case "fa":
            return "جنسیت"
        case "ar":
            return "الجنس"
        default:
            return "Kjønn";

    }
}
export const getGenderMaleText = (lang) => {
    switch (lang) {
        case "no":
            return "Gutt"
        case "en":
            return "Boy"
        case "so":
            return "Wiil"
        case "fa":
            return "پسر"
        case "ar":
            return "صبي"
        default:
            return "Gutt";

    }
}
export const getGenderFemaleText = (lang) => {
    switch (lang) {
        case "no":
            return "Jente"
        case "en":
            return "Girl"
        case "so":
            return "Gabadh"
        case "fa":
            return "دختر"
        case "ar":
            return "فتاة"
        default:
            return "Jente";

    }
}
export const getGenderMaleAdultText = (lang) => {
    switch (lang) {
        case "no":
            return "Mann"
        case "en":
            return "Male"
        case "so":
            return "Nin"
        case "fa":
            return "مرد"
        case "ar":
            return "رَجُل"
        default:
            return "Mann";

    }
}
export const getGenderFemaleAdultText = (lang) => {
    switch (lang) {
        case "no":
            return "Kvinne"
        case "en":
            return "Female"
        case "so":
            return "Gabar"
        case "fa":
            return "زن"
        case "ar":
            return " امرأة"
        default:
            return "Kvinne";

    }
}
export const getSelectText = (lang) => {
    switch (lang) {
        case "no":
            return "Velg"
        case "en":
            return "Select"
        case "so":
            return "Dooro"
        case "fa":
            return "انتخاب کن"
        case "ar":
            return "اختر"
        default:
            return "Velg";

    }
}

export const getActivityTextHeading = (lang) => {
    switch (lang) {
        case "no":
            return "Jeg kan ikke trene av medisinske årsaker."
        case "en":
            return "I can not train for medical reasons."
        case "so":
            return "Tababar ma samayn karo, sabab caafimaad darteed."
        case "fa":
            return "به دلیل مشکلات پزشکی نمی توانم ورزش کنم."
        case "ar":
            return "لا أستطيع أن أتدرب لأسباب طبية."
        default:
            return "Jeg kan ikke trene av medisinske årsaker.";

    }
}
export const getActivityTextDescription = (lang) => {
    switch (lang) {
        case "no":
            return "Enkelte kan ikke trene fordi de har store fysiske utfordringer eller begrensninger. Dette kan skyldes skader, sykdommer eller medisinske behandlinger."
        case "en":
            return "Some people cannot exercise because they have major physical challenges or limitations. This may be due to injuries, illnesses or medical treatments."
        case "so":
            return "Dadka qaar ma sameyn karaan jimicsi, sababtoo ah iyadda oo ay dhib weyni ka haysato dhaqdhaqaaqa jirkooda oo xadidan, sababtu waxay noqon kartaa dhaawacyo jireed, xanuuno kale ama waxaa u socota daaweyn."
        case "fa":
            return "برخی از افراد به دلیل داشتن مشکلات یا محدودیت های عمده فیزیکی نمی توانند ورزش کنند. این می تواند به دلیل صدمات جسمی، بیماری ها یا درمان های پزشکی باشد."
        case "ar":
            return "بعض الناس لا يستطيعون ممارسة الرياضة لأن لديهم تحديات جسدية كبيرة أو فُرص محدودة. قد يكون هذا بسبب إصابات، أمراض أو علاجات طبية."
        default:
            return "Enkelte kan ikke trene fordi de har store fysiske utfordringer eller begrensninger. Dette kan skyldes skader, sykdommer eller medisinske behandlinger.";

    }
}

export const printReportButtonText = (lang) => {
    switch (lang) {
        case "no":
            return "Print Rapport";
        case "en":
            return "Print Report";
        case "so":
            return "Daabaco warbixinta";
        case "fa":
            return "پرینت گزارش";
        case "ar":
            return "اطبع تقرير";
        default:
            return "Print Rapport";

    }
}

export const getSeeMoreText = (lang) => {
    switch (lang) {
        case "no":
            return "Les mer";
        case "en":
            return "See more";
        case "so":
            return "Wax badan arag";
        case "fa":
            return "بیشتر ببین";
        case "ar":
            return "شاهد المزيد";
        default:
            return "Les mer";

    }
}

export const getMobileOptionText = (lang) => {
    switch (lang) {
        case "no":
            return "Har ikke mobilnummer";
        case "en":
            return "Don't have a mobile number";
        case "so":
            return "Ma haysto taleefonka gacanta";
        case "fa":
            return "شماره موبایل ندارم";
        case "ar":
            return "ليس لدي رقم هاتف";
        default:
            return "Har ikke mobilnummer";

    }
}

export const getMobileOptionWriteText = (lang) => {
    switch (lang) {
        case "no":
            return "Skriv ned å ta vare på dette nummeret til neste gang du skal gjennomføre kartleggingen";
        case "en":
            return "Write down and take care of this number until next tme you complete the survey";
        case "so":
            return "Qor si aad u daryeesho lambarkan ilaa wakhtiga xiga ee aad samaynayso sahanka.";
        case "fa":
            return "شماره خود را در این قسمت وارد کنید و آن را برای دفعه بعد که می خواهید ارزیابی را انجام دهید به خاطر بسپرید.";
        case "ar":
            return "دوّن هذا الرقم واحتفظ به للمرة القادمة التي تجري فيها الاستطلاع.";
        default:
            return "Skriv ned å ta vare på dette nummeret til neste gang du skal gjennomføre kartleggingen";

    }
}

export const getIntroductionVideoText = (lang) => {
  switch (lang) {
      case "no":return "Introduksjonsfilm";
      case "en":return "Induction film";
      case "so":return "Filim hordhac ah";
      case "fa":return "فیلم مقدماتی";
      case "ar":return "فيلم تمهيدي";
      default: return  "Introduction Video";
  }
}

export const getCancelButtonText = (lang) => {
  switch (lang) {
      case "no":return "Avbryt";
      case "en":return "Cancel";
      case "so":return "Baajinta";
      case "fa":return "لغو";
      case "ar":return "إلغاء";
      default: return  "Avbryt";
  }
}
export const getUserDefinedModuleText = (lang) => {
  switch (lang) {
      case "no":return "Spørsmål";
      case "en":return "Questions";
      case "so":return "Su'aalo";
      case "fa":return "سوالات";
      case "ar":return "أسئلة";
      default: return  "Spørsmål";
  }
}
export const getPasswordSentConfirmText = (lang) => {
  switch (lang) {
      case "no":return "Du vil nå motta en SMS med et engangspassord.";
      case "en":return "You will now receive an SMS with a one-time password.";
      case "so":return "Hadda waxaad heli doontaa SMS oo wata furaha hal mar ah.";
      case "fa":return "اکنون یک پیامک با رمز یکبار مصرف دریافت خواهید کرد.";
      case "ar":return "ستتلقى الآن رسالة نصية قصيرة تحتوي على كلمة مرور لمرة واحدة.";
      default: return  "Du vil nå motta en SMS med et engangspassord.";
  }
}
export const getWriteMobileText = (lang) => {
  switch (lang) {
      case "no":return "Skriv inn ditt mobilnummer";
      case "en":return "Enter your mobile number";
      case "so":return "Geli nambarkaaga gacanta";
      case "fa":return "شماره موبایل خود را وارد کنید";
      case "ar":return "أدخل رقم هاتفك المحمول";
      default: return  "Skriv inn ditt mobilnummer";
  }
}
export const getPasswordText = (lang) => {
  switch (lang) {
      case "no":return "Passord";
      case "en":return "Password";
      case "so":return "Erayga sirta ah";
      case "fa":return "کلمه عبور";
      case "ar":return "كلمه السر";
      default: return  "Passord";
  }
}

export const getSendPasswordText = (lang) => {
  switch (lang) {
      case "no":return "Send passord";
      case "en":return "Send password";
      case "so":return "Soo dir furaha sirta ah";
      case "fa":return "ارسال رمز عبور";
      case "ar":return "إرسال كلمة المرور";
      default: return  "Send passord";
  }
}

export const getCreatePasswordButtonText = (lang) => {
  switch (lang) {
      case "no":return "Lag passord";
      case "en":return "Create Password";
      case "so":return "Samee erayga sirta ah";
      case "fa":return "رمز عبور ایجاد کنید";
      case "ar":return "أنشئ كلمة مرور";
      default: return  "Lag passord";
  }
}

export const getNoPasswordText = (lang) => {
  switch (lang) {
      case "no":return "Har du ikke passord?";
      case "en":return "Do not have a password?";
      case "so":return "Ma haysatid furaha sirta ah?";
      case "fa":return "رمز عبور ندارید؟";
      case "ar":return "هل لا يوجد لديك كلمة سر؟";
      default: return  "Har du ikke passord?";
  }
}



export const getNoPasswordTextLong = (lang) => {
  switch (lang) {
      case "no":return "Hvis du ikke har mottatt passord så kan du få dette tilsendt til din mobiltelefon.";
      case "en":return "If you have not received a password then you can have this sent to your mobile phone.";
      case "so":return "Haddii aanad helin furaha sirta ah, waxa laguu soo diri karaa taleefanka gacanta.";
      case "fa":return "اگر رمز عبور دریافت نکرده اید، می توانید آن را به تلفن همراه خود ارسال کنید.";
      case "ar":return "إذا لم تكن قد تلقيت كلمة مرور ، فيمكنك إرسالها إلى هاتفك المحمول.";
      default: return  "Hvis du ikke har mottatt passord så kan du få dette tilsendt til din mobiltelefon.";
  }
}






