const LanguageSelector = (props) => {

    function onLanguageChange(language) {

        props.changeLanguage(language)
    }

    function getOptions() {
      return props.languages.map(lang=>{
          return <button key={lang.code}
                         onClick={(event) => {
                             event.preventDefault();
                             onLanguageChange(lang.code)
                         }}
                         className={`my-1 button-gradient hover:text-white text-xl button-default w-full font-semibold 
                         ${props.selected===lang.code?"border border-green-300":''}`} >{lang.name}</button>

      })
    }

    return (
        <div className={'w-full'}>
                {getOptions()}
        </div>
    )
}
export default LanguageSelector
