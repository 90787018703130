import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Dialog, Transition} from "@headlessui/react";

class Loading extends React.Component {

    render() {
        if (this.props.pending || this.props.posted) {
            return (
                <Transition.Root show={true
                } as={Fragment} onClose={() => {
                    console.log('loading complete')
                }}>
                    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
                        <div
                            className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div
                                    className="inline-flex align-bottom justify-center justify-items-center items-center  text-center overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className={"loadIndicator"}></div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = ({pending,posted}) => {
    return {
        pending: pending,
        posted: posted,
    }
};
export default connect(mapStateToProps)(Loading);

