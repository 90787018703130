import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ModuleContainer from "./ModuleContainer";
import {getSurveyAuthentication, getSurveyJson, restartSurveyResponse, startSurveyResponse} from "../service";
import UserForm from "./shared/UserForm";
import {connect} from "react-redux";
import {
    storePersonId,
    storeResponseId,
    storeLicenseKey,
    alterLoading,
    alterErrorAlert,
    alterRequestPasswordComponentState
} from "../reducer";
import BannerAlert from './shared/BannerAlert'
import LanguageSelector from "./shared/LanguageSelector";
import Footer from "./shared/Footer";
import RequestPasswordPopup from "./shared/RequestPasswordPopup";
import VideoContainer from "./shared/VideoContainer";
import PasswordForm from "./shared/PasswordForm";
import VideoThumbnail from "./shared/VideoThumbnail";
import {getCreatePasswordButtonText, getNoPasswordText, getNoPasswordTextLong} from "./util/Translations";

const SurveyBaseContainer = (props) => {
    const params = useParams();
    const surveyId = params.surveyId; //eg: 95c02fac-552c-4050-b7a5-1ce21a69ff32
    const respId = params.responseId ? params.responseId : null; //eg: 95c02fac-552c-4050-b7a5-1ce21a69ff32
    const perId = params.personId ? params.personId : null; //eg: 95c02fac-552c-4050-b7a5-1ce21a69ff32
    const [surveyLang, setSurveyLang] = useState('no');
    const {surveyJson} = useFetchSurveyJson(surveyId, surveyLang, props.alterLoading)
    const [licenseKey, setLicenseKey] = useState('');
    const [showMainVideo, setShowMainVideo] = useState(false);
    const [key, setKey] = useState('');
    const [user, setUser] = useState(null);
    const [userUpdated, setUserUpdated] = useState(0);
    const {authenticated} = useAuthenticateSurvey(surveyId, licenseKey, props.alterErrorAlert, setKey)

    const [userGender, setUserGender] = useState();

    const {
        started,
        responseId,
        personId
    } = useStartSurvey(surveyId, licenseKey, user, authenticated, userUpdated, props.alterErrorAlert, props.alterLoading)
    if (authenticated) {
        props.storeLicenseKey(licenseKey)
        if (personId != null && responseId != null) {
            props.storePersonId(personId)
            props.storeResponseId(responseId)
        }
    }


    if (respId != null && perId != null && !props.posted) {
        props.storeResponseId(respId);
        props.storePersonId(perId);
        props.alterLoading(true)
        restartSurveyResponse(surveyId, respId).then(response => {
            props.alterLoading(false)
            setUserGender(response.gender)
        }).catch(error => {
            props.alterLoading(false)
            props.alterErrorAlert(true)
        })
    }

    function populateUser(user) {
        setUser(user)
        let up = userUpdated + 1
        setUserUpdated(up)

    }

    function getRightDirection() {
        let rtl = false;
        surveyJson.languages.map(lang => {
            if (lang.code === surveyLang)
                rtl = lang.rtl
            return true;
        })
        return rtl;
    }

    function redirectToReport(redirectUrl) {
        window.location.replace(redirectUrl)
    }

    function getBypass() {
        if (props.byPassInit && userGender)
            return true
        return !(props.byPassInit && !userGender);

    }

    return (
        <>
            <div className="lg:text-center h-full">

                {/*Submission Popup*/}
                {/*<PopUpModal*/}
                {/*    lang={surveyLang}*/}
                {/*    redirectUrl={`${process.env.REACT_APP_BACKEND_API}/survey/${surveyId}/response/${responseId}/${surveyLang}`}/>*/}
                {props.posted && redirectToReport(`${process.env.REACT_APP_BACKEND_API}/survey/${surveyId}/response/${respId ? respId : responseId}/${surveyLang}`)}

                {/*error banner*/}
                <BannerAlert/>

                {(surveyJson && getBypass()) &&
                <div
                    className={`${getRightDirection() ? 'text-right' : 'text-left'} leading-8 tracking-tight  h-full`}
                    dir={getRightDirection() ? 'rtl' : ''}>

                    <div className="h-full bg-black shadow ">
                        <img className={"h-full w-full"}
                             src={`${process.env.REACT_APP_BACKEND_API}${surveyJson.logoUrl}`}/>
                    </div>
                    {(surveyJson.introductionVideoUrl && !authenticated) &&
                    <VideoContainer
                        setShowMainVideo={setShowMainVideo} showMainVide={showMainVideo}
                        videoId={surveyJson.introductionVideoUrl} autoplay={true} activeTab={true} intro={true}
                        page={'introduction'}/>}

                    {/*Authentication Form*/}
                    {
                        (!authenticated && !started && !props.byPassInit) &&
                        <div className={'flex grey flex-col relative pt-5 '}>
                            <div
                                className={'md:pt-6 lg:pt-14 sm:px-20 grid grid-flow-col lg:grid-flow-row grid-rows-2 lg:grid-rows-1 grid-cols-1 lg:grid-cols-3'}>
                                <div className={'text-sm col-span-1 md:col-span-2  md:mr-2 p-5'}>
                                    <p className={"leading-5 survey-text"}
                                       dangerouslySetInnerHTML={{__html: surveyJson.longDescription}}
                                    ></p>
                                    {surveyJson.openSurvey &&
                                    <p className={"leading-5"}><br/><br/>
                                        <strong>{getNoPasswordText(surveyLang)}</strong> {getNoPasswordTextLong(surveyLang)}
                                        <button
                                            className={'px-4 py-1 text-sm font-semibold border rounded border-gray-600 hover:bg-gray-300'}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.alterRequestPasswordComponentState(true)
                                            }}>{getCreatePasswordButtonText(surveyLang)}</button>
                                        <RequestPasswordPopup lang={surveyLang} surveyId={surveyId}/>
                                    </p>
                                    }
                                </div>

                                <div className={"py-10 pt-0 px-6 md:max-w-sm"}>
                                    {(surveyJson.introductionVideoUrl && !showMainVideo) &&
                                    <VideoThumbnail setShowMainVideo={setShowMainVideo} showMainVide={showMainVideo}
                                                    videoId={surveyJson.introductionVideoUrl} autoplay={false}
                                                    lang={surveyLang}
                                                    activeTab={true} intro={true} page={'introduction-thumbnail'}/>
                                    }
                                    <div
                                        className={'dark-grey rounded-xl p-6 flex flex-col justify-center items-center'}>

                                        {surveyJson.languages.length > 1 && <div className={'w-full'}>
                                        <LanguageSelector changeLanguage={setSurveyLang}
                                                          languages={surveyJson.languages} selected={surveyLang}/>
                                    </div>}
                                        <PasswordForm surveyLang={surveyLang} setLicenseKey={setLicenseKey}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                    {/*User Data form*/}
                    {
                        (authenticated && !started && !props.byPassInit) &&
                        <UserForm setUser={populateUser}
                                  language={surveyLang}
                                  rightAligned={getRightDirection()}
                                  requireFirstAndLastName={surveyJson.reqFirstAndLastName}
                                  targetGroup={surveyJson.targetGroup.id}
                                  adults={surveyJson.targetGroup.adults}
                                  subGroups={surveyJson ? surveyJson.subGroups.filter(sg => sg.status !== 'INACTIVE') : null}/>
                    }


                    {/*Survey Pages*/}
                    {((authenticated && started) || props.byPassInit) &&
                    <ModuleContainer modules={surveyJson.modules}
                                     language={surveyLang}
                                     gender={userGender ? userGender : user.gender}
                                     rightAligned={getRightDirection()}
                                     questionsPerPage={surveyJson.questionsPerPage}
                                     responseId={responseId} personId={personId}
                    />
                    }

                </div>
                }

            </div>
            <Footer language={surveyLang}/>
        </>
    )

}


const useAuthenticateSurvey = (surveyId, licenseKey, alterErrorAlert, setKey) => {
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        if (licenseKey)
            getSurveyAuthentication(surveyId, licenseKey).then(response => {
                if (response.status === 204) {
                    setAuthenticated(true);
                    alterErrorAlert(false)
                }
            }).catch(error => {
                alterErrorAlert(true);
                setKey('')
                console.error('[ERROR]', error);
            })
    }, [surveyId, licenseKey])
    return {authenticated}
}

const useStartSurvey = (surveyId, licenseKey, user, authenticated, userUpdated, alterErrorBanner, alterLoading) => {
    const [responseId, setResponseId] = useState('');
    const [personId, setPersonId] = useState('');
    const [started, setStarted] = useState(false);
    useEffect(() => {
        if (authenticated && user != null) {
            alterLoading(true)
            startSurveyResponse(surveyId, licenseKey, user).then(response => {
                if (response.status !== 500) {
                    let json = response;
                    // debugger
                    setStarted(true);
                    setPersonId(json.personId);
                    setResponseId(json.responseId);
                    alterLoading(false)
                    alterErrorBanner(false)
                } else {
                    alterErrorBanner(true)
                    alterLoading(false)
                }
            })
        }

    }, [userUpdated])
    return {started, responseId, personId}

}

const useFetchSurveyJson = (surveyId, surveyLang, alterLoading) => {
    const [surveyJson, setSurveyJson] = useState(null);
    useEffect(() => {
        alterLoading(true)
        getSurveyJson(surveyId, surveyLang).then(response => {
            setSurveyJson(response)
            alterLoading(false)
        }).catch(error => {
            alterLoading(false)
            console.error('[ERROR]', error);
        })
    }, [surveyId, surveyLang])
    return {surveyJson}
}

const mapStateToProps = ({error, posted}) => {
    return {
        error: error,
        posted: posted
    }
}
export default connect(mapStateToProps, {
    storePersonId,
    storeResponseId,
    storeLicenseKey,
    alterLoading,
    alterErrorAlert,
    alterRequestPasswordComponentState
})(SurveyBaseContainer);

