import {CheckIcon, TrashIcon} from "@heroicons/react/solid";
import React, {useState} from "react";
import {getSelectText, getSubGroupText, getSubGroupTextClass} from "../util/Translations";

const SubGroup = (props) => {

    const initDropDown = 'initial';

    const [level, setLevel] = useState(initDropDown);
    const [selectedParent, setSelectedParent] = useState('');

    function getLabelText() {
        if (props.targetGroup==="1"|| props.targetGroup==="3")
            return getSubGroupTextClass(props.language)
        else
            return getSubGroupText(props.language)
    }

    function getSimpleDropDown(groups, dropDownLevel) {
        return (<div
            className={`col-span-6 sm:col-span-6 flex grid grid-cols-7 ${level === dropDownLevel ? '' : 'hidden'}`}>
            <label htmlFor="subGroup"
                   className="block col-span-7 sm:col-span-2 text-sm font-medium flex items-center">
                { getLabelText()}
            </label>
            <select
                required={true}
                onChange={(event) => {
                    event.preventDefault();
                    if (event.target.selectedIndex > 0 && groups[event.target.selectedIndex - 1].children && groups[event.target.selectedIndex - 1].children.length > 0) {
                        setLevel(event.target.value)
                        setSelectedParent(`${selectedParent}${groups[event.target.selectedIndex - 1].name}/`)

                    } else {
                        props.addPropertyToUser(event.target)
                        props.validateSubGroup(event.target.value)
                    }
                }}
                id="subGroupId"
                name={`'subGroup-'${dropDownLevel}`}
                className={"mt-1 col-span-6 sm:col-span-3 block w-full py-2 px-3 pr-8 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm"}>
                <option value={''} key={'empty'}>{getSelectText(props.language)}</option>
                {groups && groups.map((sg, index) =>
                    <option itemID={index} value={sg.id} key={sg.id}>{sg.name}</option>)}
            </select>
            <div className={'text-lg text-right flex justify-end'}>
                {props.isValidSubGroup ?
                    <CheckIcon className={'h-6 w-6'} color={'green'}/> :
                    <span className={'text-red-600 font-bold text-3xl h-5'}> *</span>}
            </div>
        </div>)
    }

    function getComplexDropDown(groups, dropDownLevel) {
        if (groups.length > 1)
            groups = groups.sort((a, b) => a.name.localeCompare(b.name, 'no'))
        return (
            <div className={'col-span-6'}>

                {getSimpleDropDown(groups, dropDownLevel)}
                {groups.map((g) => {
                    if (g.children && g.children.length > 0)
                        return getComplexDropDown(g.children.filter(s => s.status !== 'INACTIVE'), g.id)
                })}
            </div>
        )
    }


    return (
        <div className={'col-span-6'}>
            {level !== initDropDown &&
            <div className={'grid grid-cols-7'}>
                <div className={'hidden sm:block col-span-2'}></div>
                <div className={'col-span-6 sm:col-span-3'}>
                    {selectedParent}
                </div>
                <div className={'flex justify-end text-right'}>
                    <TrashIcon className={'h-5 w-5 cursor-pointer'} onClick={event => {
                        event.preventDefault();
                        setLevel(initDropDown);
                        setSelectedParent('')
                        props.validateSubGroup('');
                    }}/>
                </div>
            </div>
            }
            {getComplexDropDown(props.subGroups, initDropDown)}
        </div>
    )
}
export default SubGroup;