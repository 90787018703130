export function getSurveyJson(surveyId,language) {
    return fetch(`/rest/survey/${surveyId}/${language}`)
}

export function getSurveyAuthentication(surveyId, licenseKey) {
    return post(`/rest/survey/${surveyId}/authenticate?licenseKey=${licenseKey}`, null)
}

export function fetchLicenseKey(surveyId,mobile) {
    return fetch(`/rest/survey/${surveyId}/licensekey?mobile=${mobile}`)
}

export function startSurveyResponse(surveyId, licenseKey, user) {
    let body = user;
    body['licenseKey'] = licenseKey;
    return post(`/rest/survey/${surveyId}/start`, body)
}
export function restartSurveyResponse(surveyId,  responseId) {
    return fetch(`/rest/survey/${surveyId}/start/${responseId}`)
}

export function submitSurveyResponse(surveyId, licenseKey, personId, responseId, ignoreActivity, answers,language) {
    let body = {
        licenseKey: licenseKey,
        personId: personId,
        responseId: responseId,
        ignoreActivity: ignoreActivity,
        answers: answers,
        language:language
    };
    return post(`/rest/survey/${surveyId}/complete`, body);
}

//request.
function fetch(request) {
    return new Promise((resolve, reject) => {

        window.fetch(process.env.REACT_APP_BACKEND_API + `${request}`, {credentials: 'same-origin'})
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 0) {
                        throw Error("No internet Connection.");
                    } else if (response.status === 500) {
                        return response.json() // return the result of the inner promise, which is an error
                            .then((json) => {
                                if (json.redirectUrl) {
                                    window.location = json.redirectUrl;
                                    return null;
                                } else
                                    throw new Error(json);
                            });
                    } else if (response.status === 403) {
                        throw Error("403");
                    } else {
                        throw Error("Failed to fetch, Please try again.");
                    }
                } else {
                    return response.json();
                }
            })
            .then(resolve)
            .catch(reject);
    });
}

//post request.
function post(request, body) {
    let params = {
        credentials: 'same-origin', method: 'post', headers: {
            "Content-Type": "application/json",
        }
    }
    if (body) params['body'] = JSON.stringify(body);
    return new Promise((resolve, reject) => {


        window.fetch(process.env.REACT_APP_BACKEND_API + `${request}`, params)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 0) {
                        throw Error("No internet Connection.");
                    } else if (response.status === 500) {
                        return response // return the result of the inner promise, which is an error

                    } else if (response.status === 403) {
                        throw Error("403");
                    } else {
                        throw Error("Failed to fetch, Please try again.");
                    }
                } else {
                    if (response.status === 204)
                        return response;
                    else
                        return response.json()
                }
            })
            .then(resolve)
            .catch(reject);
    });
}
